.staff-main {
  padding-top: 83px;
  padding-bottom: 116px;
}

.staff-main__wrapper {
  position: relative;
}

.staff-main__card-employee {
  width: 316px;
}

.staff-main__swiper-container {
  max-width: 1360px;
  margin: 0 auto;
  overflow: hidden;
}

.staff-main__swiper-wrapper {
  @include reset-list;

  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  max-width: 1360px;
  padding: 0;
}

.staff-main__swiper-slide {
  flex-shrink: 0;
  max-width: 316px;
}

.staff-main__swiper-button {
  position: absolute;
  top: 50%;

  width: 47px;
  height: 47px;

  border: 1px solid $color-light-grey;
  border-radius: 5px;

  svg {
    position: absolute;
    top: 16px;
    left: 18px;

    fill: $color-light-grey;
  }

  &:hover,
  &:focus {
    opacity: 0.7;
    cursor: pointer;
  }

  &--next {
    right: 20px;
  }

  &--prev {
    left: 20px;
  }
}

.staff-main__swiper-pagination {
  position: absolute;
  bottom: -64px;
  left: 49.6%;

  transform: translate(-50%, -50%);

  .swiper-pagination-bullet {
    width: 12px;
    height: 12px;
    display: inline-block;
    border-radius: 100%;

    margin: 0 8px;

    vertical-align: middle;

    background-color: #c7c7c7;

    &:hover,
    &:focus {
      opacity: 0.7;
      cursor: pointer;
    }
  }

  .swiper-pagination-bullet-active {
    width: 18px;
    height: 18px;

    background-color: $color-blue;
  }
}

@media (max-width: 1520px) {
  .staff-main__swiper-container {
    max-width: 1014px;
  }

  .staff-main__swiper-wrapper {
    max-width: 1014px;
  }
}

@media (max-width: 1175px) {
  .staff-main__swiper-container {
    max-width: 665px;
  }

  .staff-main__swiper-wrapper {
    max-width: 665px;
  }
}

@media (max-width: $desktop-width) {
  .staff-main {
    padding-top: 0;
    padding-bottom: 90px;
  }

  .staff-main__wrapper {
    padding-top: 30px;
  }
}

@media (max-width: 840px) {
  .staff-main__swiper-container {
    max-width: 316px;
  }

  .staff-main__swiper-wrapper {
    max-width: 316px;
  }
}

@media (max-width: $tablet-max-width) {
  .staff-main__swiper-button--prev {
    left: 50px;
  }

  .staff-main__swiper-button--next {
    right: 50px;
  }
}

@media (max-width: $mobile-width) {
  .staff-main__wrapper {
    padding-top: 0;
  }

  .staff-main__swiper-button {
    display: none;
  }

  .staff-main__swiper-pagination {
    bottom: -64px;

    .swiper-pagination-bullet {
      width: 6px;
      height: 6px;
      margin: 0 4px;
    }

    .swiper-pagination-bullet-active {
      width: 9px;
      height: 9px;
    }
  }
}
