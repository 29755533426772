.partners-main {
  padding-top: 89px;
  padding-bottom: 54px;
}

.partners-main__wrapper {
  position: relative;
}

.partners-main__section-header {
  margin-bottom: 31px;
}

.partners-main__swiper-container {
  max-width: 1360px;
  margin: 0 auto;

  overflow: hidden;
}

.partners-main__swiper-wrapper {
  @include reset-list;

  display: flex;
  justify-content: space-between;
  max-width: 1396px;
  margin: 0 auto;
}

.partners-main__swiper-slide {
  flex-shrink: 0;
  max-width: 260px;

  text-align: center;
}

.partners-main__image {
  width: 260px;
  height: 260px;
}

.partners-main__swiper-button {
  position: absolute;
  top: 54%;

  width: 47px;
  height: 47px;

  border: 1px solid $color-light-grey;
  border-radius: 5px;

  svg {
    position: absolute;
    top: 16px;
    left: 18px;

    fill: $color-light-grey;
  }

  &:hover,
  &:focus {
    opacity: 0.7;
    cursor: pointer;
  }

  &--next {
    right: 20px;
  }

  &--prev {
    left: 20px;
  }
}

@media (max-width: 1520px) {
  .partners-main__swiper-container {
    max-width: 1088px;
  }

  .partners-main__swiper-wrapper {
    max-width: 1088px;
  }
}

@media (max-width: 1250px) {
  .partners-main__swiper-container {
    max-width: 812px;
  }

  .partners-main__swiper-wrapper {
    max-width: 812px;
  }
}

@media (max-width: $tablet-max-width) {
  .partners-main {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .partners-main__swiper-container {
    max-width: 536px;
  }

  .partners-main__swiper-wrapper {
    max-width: 536px;
  }

  .partners-main__swiper-button--prev {
    left: 50px;
  }

  .partners-main__swiper-button--next {
    right: 50px;
  }
}

@media (max-width: $mobile-width) {
  .partners-main__swiper-container {
    max-width: 260px;
  }

  .partners-main__swiper-wrapper {
    max-width: 260px;
  }

  .partners-main__swiper-button {
    display: none;
  }
}
