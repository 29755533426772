.services {
  padding-bottom: 130px;

  &__menu {
    margin-bottom: 20px;
  }

  @include mobile {
    padding-bottom: 40px;
  }
}
