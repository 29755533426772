.rating {
  &__star {
    margin-left: 3px;

    svg {
      fill: #bcbcbc;
    }

    &--active svg {
      fill: $color-orange;
    }
  }
}
