@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src:
    local("Open Sans Regular"),
    local("OpenSans-Regular"),
    url("../fonts/OpenSans.woff2") format("woff2"),
    url("../fonts/OpenSans.woff") format("woff");
}

@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src:
    local("Open Sans SemiBold"),
    local("OpenSans-SemiBold"),
    url("../fonts/OpenSans-Semibold.woff2") format("woff2"),
    url("../fonts/OpenSans-Semibold.woff") format("woff");
}

@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src:
    local("Open Sans Bold"),
    local("OpenSans-Bold"),
    url("../fonts/OpenSans-Bold.woff2") format("woff2"),
    url("../fonts/OpenSans-Bold.woff") format("woff");
}

@font-face {
  font-family: "rouble";
  src: url("../fonts/rouble.otf") format("opentype");
}

.rub {
  font-family: "rouble";
}
