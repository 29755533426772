.card-news {
  width: 100%;
  height: 100%;

  &:hover,
  &:focus {
    .card-news__desc {
      background-color: $color-card-service-hover;
    }
  }

  a {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
}

.card-news__img {
  width: 100%;
  height: 266px;
  display: block;
  object-fit: cover;

  background-color: $color-ultra-light-grey;
}

.card-news__desc {
  position: relative;

  width: 100%;
  padding: 21px 14px 17px 32px;
  display: flex;
  flex-grow: 1;
  flex-direction: column;

  background-color: $color-ultra-light-grey;

  &::after {
    content: "";
    position: absolute;
    bottom: 12px;
    right: 23px;

    display: block;
    width: 24px;
    height: 24px;

    background-image: url(../img/sharp-arrow_back-24px.svg);
    background-repeat: no-repeat;
    background-position: center center;
  }
}

.card-news__data {
  font-size: 14px;
  line-height: 19px;
  color: $color-light-grey;
}

.card-news__title {
  margin-top: 8px;
  margin-bottom: 45px;

  font-size: 18px;
  line-height: 31px;
  color: $color-dark-grey;
}

.card-news__detaied {
  margin-top: auto;
  margin-bottom: 0;

  font-size: 14px;
  line-height: 19px;
  color: $color-blue;
}
