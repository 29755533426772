//  ======== Карточка документа =============================================

.card-document {
  min-height: 350px;

  border: 1px solid $color-card-partner-border;

  a {
    height: 100%;
    padding: 33px 10px 15px;

    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__media-wrap {
    position: relative;
    width: 144px;
    height: 198px;
    margin-bottom: 21px;
    background-color: $color-light-grey;

    img {
      display: block;
    }

    &::before {
      @include pseudo;
      top: 0;
      left: 0;

      display: none;
      width: 100%;
      height: 100%;

      background-color: $color-card-document-hover-bg;
    }

    &::after {
      @include pseudo;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      display: none;
      width: 45px;
      height: 45px;

      background-repeat: no-repeat;
      background-position: center center;
      background-image: url("../img/sharp-zoom_in-24px.svg");
    }
  }

  &__name {
    margin: 0;
    margin-bottom: 8px;
    padding: 0;

    // @include cut-text(2);

    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    color: $color-dark-grey;
    text-align: center;
  }

  &__desc {
    margin: 0;
    margin-top: auto;
    padding: 0;

    font-size: 14px;
    line-height: 25px;
    color: $color-light-grey;
  }
}

.card-document.card-document--not-loaded {
  .card-document__media-wrap::before {
    display: block;
  }

  .card-document__media-wrap::after {
    display: block;
    background-image: url("../img/sharp-get_app-24px.svg");
  }
}

// @media (min-width: $tablet-width) {
  .card-document:hover,
  .card-document:focus {
    .card-document__name {
      color: $color-blue;
    }

    .card-document__media-wrap::before,
    .card-document__media-wrap::after {
      display: block;
    }
  }

  .card-document:active {
    opacity: 0.7;
  }
// }
