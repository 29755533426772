.pagination {
  @include reset-list;
  display: flex;

  &__item {
    width: 50px;
    height: 50px;
    margin-right: 8px;

    font-size: 16px;
    line-height: 22px;

    &--back {
      margin-right: 16px;
    }

    &--forward {
      margin-left: 16px;
      margin-right: 0;
    }

    a {
      width: 100%;
      height: 100%;

      display: flex;
      justify-content: center;
      align-items: center;

      color: $color-light-grey;
      background-color: $color-white;
      border: 1px solid $color-pagination-border;
      border-radius: 4px;

    }

    &--current {
      a {
        color: $color-white;
        background-color: $color-blue;
        border-color: $color-blue;
      }
    }

    svg {
      fill: $color-light-grey;
      margin-left: 6px;
    }

    &:nth-last-child(2) {
      margin-right: 0;
    }

    &:nth-child(2),
    &:nth-last-child(3) {
      position: relative;
      margin-right: 34px;
    }

    &:nth-child(2)::after,
    &:nth-last-child(3)::after {
      @include pseudo($content: "...");
      bottom: 12px;
      right: -23px;
    }
  }
}

@media (max-width: $mobile-width) {
  .pagination__item:nth-child(2),
  .pagination__item:nth-child(3),
  .pagination__item:nth-last-child(2),
  .pagination__item:nth-last-child(3),
  .pagination__item:nth-last-child(4) {
    display: none;
  }

  .pagination__item--forward {
    margin-left: 8px;
  }
}

// @media (min-width: $tablet-width) {
  .pagination__item a:hover,
  .pagination__item a:focus {
    color: $color-white;
    background-color: $color-blue;
    border-color: $color-blue;

    svg {
      fill: $color-white;
    }
  }

  .pagination__item:not(.pagination__item--current):active {
    opacity: 0.7;
  }
// }
