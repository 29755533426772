.card-production {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 32px 10px;

  border: 1px solid $color-ultra-light-grey;

  &:hover,
  &:focus {
    background-color: $color-ultra-light-grey;
  }
}

.card-production__image {
  display: block;
  width: 214px;
  height: 214px;
}

.card-production__title {
  margin-top: 11px;
  margin-bottom: 0;

  font-weight: 700;
  color: $color-dark-grey;
  text-align: center;
  letter-spacing: -0.6px;
}

.card-production__count {
  margin-top: auto;

  font-size: 14px;
  color: $color-light-grey;
}
