.tab {
  width: 100%;

  &__menu {
    position: relative;
    margin-bottom: 40px;

    border-bottom: 1px solid $color-tabmenu-border;
  }

  &__list {
    @include reset-list;

    display: flex;
  }

  &__item {
    margin-right: 40px;
    padding: 0;
    padding-bottom: 6px;

    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.7px;
    text-transform: uppercase;
    color: $color-dark-grey;
    background-color: transparent;
    white-space: nowrap;
    border: none;
    border-bottom: 3px solid transparent;
    cursor: pointer;

    &:last-child {
      margin-right: 0;
    }

    &[aria-selected=true] {
      color: $color-blue;
      border-bottom: 3px solid $color-blue;
    }
  }

  .tab__item.swiper-slide {
    width: auto !important;
  }
}

.tab button:hover {
  color: $color-blue;
}

.tab button:focus {
  outline: none;
}

.tab button:active {
  opacity: 0.7;
}
