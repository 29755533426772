.product-card {
  .inner-page__column--left {
    width: 20.8%;
    margin-right: 10px;
  }

  .inner-page__column--right {
    width: 74.7%;
    padding-right: 10px;

    @media (max-width: $breakpoint-desktop) {
      width: 100%;
      padding-right: 0;
    }
  }

  &__info {
    margin-bottom: 65px;
    display: flex;
    justify-content: space-between;

    @media (max-width: 1480px) {
      flex-direction: column;
      align-items: center;
    }

    @media (max-width: $breakpoint-desktop) {
      margin-left: 0;
      flex-direction: row;
      align-items: flex-start;
    }

    @include mobile {
      flex-direction: column;
      align-items: center;
    }
  }

  &__photos {
    width: 44.2%;

    @media (max-width: 1480px) {
      width: 100%;
      max-width: 600px;
    }

    @media (max-width: $breakpoint-desktop) {
      width: 44.2%;
    }

    @include mobile {
      width: 100%;
    }
  }

  &__container {
    width: 50.6%;

    @media (max-width: 1480px) {
      width: 100%;
      max-width: 600px;
    }

    @media (max-width: $breakpoint-desktop) {
      width: 50.6%;
    }

    @include mobile {
      width: 100%;
    }
  }

  &__photo {
    border: 1px solid $color-product-photo-border;

    &--big {
      padding: 25px;
      padding-bottom: 30px;
      margin-bottom: 16px;
      cursor: pointer;
    }

    &--small {
      width: 22.7%;
      margin-right: 2.8%;
      margin-bottom: 15px;
      padding: 6px 5px;

      &:nth-child(4n) {
        margin-right: 0;
      }

      .product-card__media-wrap {
        cursor: pointer;
      }
    }
  }

  &__photo-container {
    display: flex;
    flex-wrap: wrap;
  }

  img {
    width: 100%;
    display: block;
    object-fit: cover;
  }

  &__rating {
    margin-left: -5px;
    margin-top: -2px;
    margin-bottom: 8px;
    height: 16px;
  }

  &__specifications {
    margin-bottom: 28px;

    a {
      display: inline-block;
      font-size: 15px;
      line-height: 20px;
      color: $color-blue;
      border-bottom: 1px dotted $color-blue;
    }
  }

  &__spec-list {
    @include reset-list;
    margin-bottom: 12px;
  }

  &__spec-item {
    width: 100%;
    display: flex;
    justify-content: space-between;

    font-size: 14px;
    line-height: 31px;
    letter-spacing: -0.5px;
    color: $color-light-grey;
  }

  &__spec-name {
    margin-right: -50%;
    width: 100%;
    vertical-align: top;
    overflow: hidden;
    display: inline-block;
    letter-spacing: normal;

    &::after {
      content: '';
      display: inline-block;
      width: 100%;
      margin: 0 -99% 0 3px;
      vertical-align: baseline;
      border-bottom: 1px dotted #888;
    }
  }

  &__spec-value {
    display: inline-block;
    letter-spacing: normal;
    padding-left: 3px;
    margin-left: 50%;
    vertical-align: bottom;
    white-space: nowrap;
    align-self: flex-end;
  }

  &__price {
    width: 50%;
    display: flex;
    flex-direction: column;
  }

  &__product-price {
    margin-left: 5px;
    margin-top: -10px;
    margin-bottom: 13px;

    font-weight: 700;
    font-size: 36px;
    line-height: 49px;
    color: $color-dark-grey;
    letter-spacing: -1px;
    white-space: nowrap;

    &--old {
      margin: 0;
      font-weight: 400;
      font-size: 14px;
      line-height: 25px;
      color: $color-product-old-price;
      text-decoration: line-through;
      letter-spacing: normal;
    }
  }

  &__button-buy {
    width: 235px;
    margin-bottom: 10px;
  }

  &__product-availability {
    font-weight: 400;
    font-size: 14px;
    line-height: 25px;
    color: $color-light-grey;
  }

  &__tab {
    margin-bottom: 60px;

    .tab__menu {
      margin-bottom: 25px;
    }

    @media (max-width: $breakpoint-desktop) {
      margin-left: 0;
    }
  }

  &__menu {
    margin-bottom: 16px;
  }

  table {
    margin-bottom: 55px;
  }

  &__desc {
    margin-bottom: 82px !important;
  }

  .list {
    margin-bottom: 0;
  }

  .reviews-main {
    padding: 0;

    .reviews-main__review {
      padding-right: 85px;
      // margin-bottom: 33px;
    }

    .reviews-main__rating-items {
      max-width: 905px;
    }

    .reviews-main__grey-wrapper {
      padding-left: 47px;
      padding-right: 40px;
      padding-top: 50px;
    }

    .reviews-main__reviewer-name {
      margin-left: 0;
      line-height: 22px;
      letter-spacing: normal;
    }

    .reviews-main__review-text {
      max-width: none;
      margin-bottom: 33px;
      letter-spacing: -0.1px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    .reviews-main__text-header {
      // margin-top: 0;
      letter-spacing: normal;
    }
  }

  &__reviews-list {
    @include reset-list;

    display: block;
  }

  &__reviews-item {
    margin-bottom: 40px;

    &:last-child {
      margin-bottom: 0;
    }

    .reviews-main__grey-wrapper {
      padding-bottom: 13px;
      min-height: 0;
    }

    .reviews-main__review {
      min-height: 0;
    }
  }

  &__similar {
    position: relative;

    @media (max-width: $breakpoint-desktop) {
      margin-left: 0;
      margin-right: 0;
    }
  }

  &__similar-swiper-container {
    overflow: hidden;
  }

  &__similar-control {
    position: absolute;
    top: 51.5%;
    z-index: 10;
  }

  .control--left {
    left: -60px;
  }

  .control--right {
    right: -60px;
  }

  &__similar-title {
    margin-bottom: 22px;
  }

  &__similar-items {
    @include reset-list;

    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    }

  &__similar-item {
    flex-shrink: 0;
  }

  .button--menu-left {
    margin-bottom: 20px;
    display: none;
  }

  .button--active {
    color: $color-white;
    background-color: $color-blue;
  }

  @media (max-width: $breakpoint-desktop) {
    .button--menu-left {
      display: block;
    }

    .inner-page__wrap {
      margin-bottom: 0;
      flex-direction: column;
    }

    .inner-page__column--left {
      width: 100%;
      display: block;

      .buttons {
        display: none;
      }
    }

    .menu--left {
      display: none;
    }

    .menu--left-opened {
      display: block;
    }
  }

  @include mobile {
    &__similar {
      padding: 0;
    }

    &__similar-control {
      display: none;
    }

    .reviews-main {
      .reviews-main__grey-wrapper {
        padding-left: 20px;
        padding-right: 20px;
      }

      .reviews-main__answer {
        padding-left: 50px;
      }

      .reviews-main__review {
        padding: 30px 15px;
      }
    }
  }

  // @media (min-width: $tablet-width) {
    &__specifications a:hover,
    &__specifications a:focus {
      opacity: 0.7;
    }

    &__specifications a:active {
      opacity: 1;
    }
  }
// }
