.table {
  table {
    width: 100%;

    color: $color-dark-grey;
    border-collapse: collapse;
  }

  tr {
    &:nth-child(2n + 1) {
      background-color: $color-ultra-light-grey;
    }
  }

  td {
    padding: 15px 0;
    padding-right: 15px;

    font-size: 16px;
    line-height: 30px;

    &:first-child {
      width: 34%;

      font-weight: 700;
      line-height: 22px;

      padding-left: 30px;
      // padding-right: 15px;
    }
  }

  @include tablet {
    td:first-child {
      width: 44%;
    }
  }

  @include mobile {
    td:first-child {
      padding-left: 15px;
    }
  }
}
