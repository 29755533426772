.services-card {
  &__introduction {
    position: relative;
    width: 100%;
    height: 470px;
    margin-bottom: 50px;

    border: 1px solid $color-blue;

    background-repeat: no-repeat;
    background-position: 0 0;
    background-size: cover;
    color: $color-white;
    background-color: #9e8993;
    background-image: url("../img/services/office-1209640_1920.jpg");

    @media (min-resolution: $retina-dpi), (min-resolution: $retina-dppx) {
      background-image: url("../img/services/office-1209640_1920@2x.jpg");
    }
  }

  &__container {
    position: absolute;
    bottom: 0;
    left: 0;

    min-height: 120px;
    padding: 37px 44px;
    padding-right: 35px;

    display: flex;

    background-color: $color-servces-card-container-bg;

    p {
      width: 55%;
      margin: 0;
      padding: 0;

      font-size: 14px;
      line-height: 25px;
      letter-spacing: -0.2px;
    }
  }

  &__menu {
    margin-bottom: 16px;
  }

  &__buttons {
    margin-left: auto;
    align-self: center;

    .button:first-child {
      margin-right: 10px;
    }
  }

  .services-card__desc {
    margin-bottom: 73px;
  }

  &__features,
  &__employees {
    margin-bottom: 60px;
  }

  .list,
  &__gallery {
    margin-bottom: 64px;
  }

  .list--card {
    margin-bottom: 50px;
  }

  &__employees.list {
    margin-bottom: 62px;
  }

  &__faq,
  &__vacancy {
    margin-bottom: 50px;

    .page-title--h2 {
      margin-bottom: 22px;
    }
  }

  &__faq {
    .page-title--h2 {
      margin-bottom: 27px;
    }
  }

  &__vacancy {
    margin-bottom: 0;
  }

  .button--menu-left {
    margin-bottom: 20px;
    display: none;
  }

  .button--active {
    color: $color-white;
    background-color: $color-blue;
  }

  @media (max-width: $breakpoint-desktop) {
    .button--menu-left {
      display: block;
    }

    .inner-page__wrap {
      margin-bottom: 0;
      flex-direction: column;
    }

    .inner-page__column--left {
      width: 100%;
      display: block;

      .buttons {
        display: none;
      }
    }

    .menu--left {
      display: none;
    }

    .menu--left-opened {
      display: block;
    }
  }

  @include tablet {
    &__introduction {
      margin-bottom: 30px;
    }

    &__container {
      height: 200px;
      padding-top: 30px;

      flex-direction: column;

      p {
        width: 100%;
        -webkit-line-clamp: 3;
      }
    }

    &__buttons {
      margin: 0;
      margin-top: auto;

      .button {
        width: 250px;
      }
    }

    .services-card__desc,
    &__features,
    &__gallery,
    &__projects,
    &__publications,
    &__partners,
    &__faq,
    &__employees {
      margin-bottom: 40px;
    }
  }

  @include mobile {
    &__introduction {
      height: auto;
      padding: 20px;
      background-image: none;
    }

    &__container {
      position: relative;
      padding: 30px;
      // padding-top: 30px;
      height: auto;

      flex-direction: column;

      p {
        width: 100%;
        margin-bottom: 20px;
        // -webkit-line-clamp: 4;
      }
    }

    &__buttons {
      width: 100%;
      margin: 0;
      margin-top: auto;

      .button {
        width: 100%;

        &:first-child {
          margin-right: 0;
          margin-bottom: 15px;
        }
      }
    }
  }
}
