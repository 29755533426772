.filter {
  position: relative;

  font-size: 15px;
  line-height: 20px;
  color: $color-grey;
  background-color: $color-ultra-light-grey;
  border: 1px solid $color-super-light-grey;

  &--closed {
    display: none;
  }

  &__control {
    width: 24px;
    height: 24px;

    top: 37px;
    right: 32px;

    display: none;
  }

  & > p {
    margin: 0;
    padding: 19px 33px;

    font-weight: 700;
    color: $color-white;
    background-color: $color-blue;
  }

  fieldset {
    padding: 18px 33px 23px;
    padding-right: 6.3%;
    border: none;
    border-bottom: 1px solid $color-super-light-grey;

    &:first-child {
      padding-right: 33px;
      padding-bottom: 29px;

      .filter__name {
        padding-bottom: 18px;
        cursor: default;
      }
    }

    &:last-child {
      padding-top: 5px;
      padding-bottom: 5px;
      border-bottom: none;
    }
  }

  &__wrap {
    position: relative;
    margin-bottom: 8px;

    display: flex;
    justify-content: space-between;
    align-items: center;

    hr {
      width: 15%;
      margin-left: 3px;
      margin-right: 3px;
    }
  }

  &__price-range {
    p {
      margin: 0;
      margin-bottom: 10px;
      padding: 0;

      display: flex;
      justify-content: space-between;
    }
  }

  &__scale {
    position: relative;
    content: "";
    width: 100%;
    height: 3px;
    background-color: $color-filter-scale-bg;
  }

  input[type="number"] {
    width: 112px;
    padding: 6px 12px;

    color: $color-grey;
    text-align: center;

    border: 1px solid $color-grey;
    border-radius: 3px;
    background-color: $color-white;

    appearance: none;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }

    -moz-appearance: textfield;
  }

  &__list {
    @include reset-list;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;

    &--closed {
      display: none;
    }
  }

  &__item {
    margin-bottom: 14px;

    &:last-child {
      margin-bottom: 0;
    }

    input[type="checkbox"],
    input[type="radio"] {
      display: none;
    }

    label {
      position: relative;
      // width: 100%;
      padding-left: 30px;
      display: inline-block;

      cursor: pointer;
    }

    input[type="checkbox"] + label::before {
      @include pseudo;
      left: 0;
      top: 0;

      width: 20px;
      height: 20px;
      background-color: $color-white;
      border: 1px solid $color-grey;
      border-radius: 3px;
    }

    input[type="checkbox"] + label::after {
      @include pseudo;
      left: 3px;
      top: 5px;

      width: 13px;
      height: 10px;

      display: none;

      background-repeat: no-repeat;
      background-position: center center;
      background-image: url("../img/checkbox.svg");
    }

    input[type="checkbox"]:checked + label::after {
      display: block;
    }

    input[type="radio"] + label::before {
      @include pseudo;
      left: 0;
      top: 0;

      width: 20px;
      height: 20px;
      background-color: $color-white;
      border: 1px solid $color-grey;
      border-radius: 50%;
    }

    input[type="radio"] + label::after {
      @include pseudo;
      left: 6px;
      top: 6px;

      width: 8px;
      height: 8px;

      display: none;

      background-color: $color-blue;
      border-radius: 50%;
    }

    input[type="radio"]:checked + label::after {
      display: block;
    }
  }

  &__name {
    position: relative;
    padding-right: 13px;
    cursor: pointer;

    svg {
      position: absolute;
      top: 3px;
      right: 0;
      fill: $color-blue;
    }

    span {
      font-weight: 700;
    }

    &--list-opened {
      padding-bottom: 18px;

      svg {
        transform: rotate(180deg);
      }
    }

    &--list-closed {
      padding-bottom: 0;
    }
  }

  &__buttons {
    display: flex;
  }

  &__button {
    &--reset {
      @include reset-button;
      margin-right: 5px;
      color: $color-blue;
      text-decoration: underline;
    }

    &--submit {
      width: 64%;
      margin-left: auto;
    }
  }

  @media (max-width: 1187px) {
    &__control {
      display: block;
    }

    & > p {
      text-align: center;
      color: $color-blue;
      background-color: $color-ultra-light-grey;
      border-bottom: 1px solid $color-super-light-grey;
    }

    &__wrap {
      hr {
        width: 75%;
      }
    }
    // &__list {
    //   flex-direction: row;
    // }

    // &__item {
    //   width: 20%;
    // }
  }

  @include mobile {
    .filter__wrap label {
      width: 45%;
    }

    input[type="number"] {
      width: 100%;
      min-width: 100px;

      &:first-child {
        margin-right: 7px;
      }
    }

    &__list {
      flex-direction: column;
    }

    &__item {
      width: 100%;
    }
  }

  // @media (min-width: $tablet-width) {
    &__button--reset:hover,
    &__button--reset:focus {
      opacity: 0.7;
      outline: none;
    }

    &__button--reset:active {
      opacity: 1;
    }

    &__item {
      label:hover,
      label:focus {
        opacity: 0.7;
        outline: none;
      }

      label:active {
        opacity: 1;
      }
    }

    fieldset:not(:first-child) {
      .filter__name:hover {
        opacity: 0.7;
      }

      .filter__name:active {
        opacity: 1;
      }
    }

    input[type="number"]:hover {
      opacity: 0.7;
    }
  }
// }
