.body-container {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  overflow-x: hidden;

  &__content {
    flex: 1 0 auto;
  }

  .page-footer {
    flex: 0 0 auto;
  }
}
