.vacancy {
  width: 100%;

  &__list {
    @include reset-list;

    .vacancy__item {
      margin-bottom: 8px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__item {
    position: relative;
    min-height: 160px;

    padding-top: 15px;
    padding-bottom: 33px;
    padding-left: 40px;
    padding-right: 34px;

    display: flex;
    flex-direction: column;

    border: 1px solid $color-super-light-grey;

    & > p {
      margin: 0;

      font-size: 13px;
      line-height: 30px;
      color: $color-light-grey;
      text-transform: uppercase;
    }

    &--closed {
      padding-bottom: 20px;
      background-color: $color-ultra-light-grey;

      .vacancy__details {
        display: none;
      }

      .vacancy__desc {
        a {
          span:nth-child(2) {
            display: none;
          }
        }

        svg {
          transform: rotate(90deg);
        }
      }
    }

    &--opened {
      padding-bottom: 33px;

      .vacancy__details {
        display: block;
      }

      .vacancy__desc {
        a {
          span:first-child {
            display: none;
          }
        }

        svg {
          transform: rotate(-90deg);
        }
      }
    }
  }

  &__desc {
    margin-top: -2px;
    margin-bottom: 18px;

    display: flex;
    align-items: center;

    p {
    margin: 0;
    margin-right: 10px;
    // margin-left: auto;
    padding: 0;

    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    color: $color-dark-grey;
    white-space: nowrap;
    }

    a {
      width: 122px;
      // margin-left: 4.5%;
      margin-left: auto;
      padding-top: 2px;

      display: flex;
      justify-content: flex-end;
      align-items: center;

      span {
        margin-right: 15px;

        font-size: 14px;
        line-height: 19px;
        color: $color-blue;
        text-decoration: underline;
        letter-spacing: 0.7px;
      }
    }

    svg {
      fill: $color-light-grey;
    }
  }

  &__name {
    width: 57%;
    margin: 0;
    margin-right: 10px;
    padding: 0;

    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    color: $color-dark-grey;
  }

  &__desc2 {
    p {
      margin: 0;

      font-size: 13px;
      line-height: 27px;
      color: $color-light-grey;
    }
  }

  &__details {
    margin-top: 30px;

    .vacancy__details-title {
      font-weight: 700;
      font-size: 16px;
      line-height: 22px;
      color: $color-dark-grey;
    }

    p {
      margin: 0;
      margin-bottom: 25px;
      font-size: 16px;
      line-height: 30px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    ul {
      @include reset-list;
      width: 85%;
      margin-bottom: 35px;

      font-size: 16px;
      line-height: 30px;
    }

    li {
      position: relative;
      margin-bottom: 5px;
      padding-left: 25px;

      &:last-child {
        margin-bottom: 0;
      }

      &::before {
        @include pseudo;
        top: 15px;
        left: 0;
        width: 17px;
        height: 1px;
        background-color: $color-light-grey;
      }
    }
  }

  &__button {
    display: block;
  }

  @include tablet {
    &__name {
      width: 47%;
    }
  }

  @include mobile {
    &__item {
      padding: 20px;
      padding-right: 45px;

      & > p {
        margin-bottom: 15px;
      }
    }

    &__desc {
      flex-direction: column;
      align-items: flex-start;

      p {
        margin: 0;
        font-size: 16px;
        line-height: 22px;
      }

      a {
        width: auto;

        span {
          display: none;
        }
      }

      svg {
        // width: 15px;
        // height: 25px;
        position: absolute;
        right: 20px;
        // top: 170px;
        bottom: 20px;
      }
    }

    &__name {
      margin-bottom: 5px;
      font-size: 16px;
      line-height: 22px;
    }
  }

  // @media (min-width: $tablet-width) {
    a:hover,
    a:focus {
      opacity: 0.7;

      svg {
        fill: $color-blue;
      }
    }

    a:active {
      opacity: 1;
    }
  }
// }
