.faq {
  width: 100%;

  &__list {
    @include reset-list;
  }

  &__item {
    padding-left: 37px;
    padding-right: 11px;
    padding-top: 8px;
    padding-bottom: 33px;
    margin-bottom: 16px;

    border: 1px solid $color-super-light-grey;

    :last-child {
      margin-bottom: 0;
    }

    &--closed {
      padding-bottom: 8px;
      background-color: $color-ultra-light-grey;

      .faq__answer {
        display: none;
      }
    }

    &--opened {
      padding-bottom: 33px;
      background-color: $color-white;

      .faq__answer {
        display: block;
      }
    }
  }

  &__question {
    display: flex;
    align-items: center;

    p {
      margin: 0;
      padding: 0;
      margin-right: 20px;

      // overflow: hidden;
      // white-space: nowrap;
      // text-overflow: ellipsis;

      font-weight: 700;
      font-size: 16px;
      line-height: 22px;
      color: $color-dark-grey;

      border-bottom: 1px dashed $color-dark-grey;
    }
  }

  &__answer {
    & > p {
      margin: 0;
      margin-bottom: 15px;
      font-weight: 700;
      font-size: 16px;
      line-height: 22px;
      color: $color-dark-grey;
    }

    ul {
      @include reset-list;
      width: 85%;
      margin-bottom: 35px;

      font-size: 16px;
      line-height: 30px;
    }

    li {
      position: relative;
      margin-bottom: 5px;
      padding-left: 25px;

      &:last-child {
        margin-bottom: 0;
      }

      &::before {
        @include pseudo;
        top: 15px;
        left: 0;
        width: 17px;
        height: 1px;
        background-color: $color-light-grey;
      }
    }
  }

  &__answer-desc {
    width: 85%;
    margin-top: 25px;
    margin-bottom: 25px;

    p {
      margin: 0;
      margin-bottom: 25px;
      font-size: 16px;
      line-height: 30px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__control {
    margin-left: auto;
    flex-shrink: 0;
  }

  &__button {
    display: block;
  }

  @include mobile {
    font-size: 16px;
    line-height: 22px;

    &__item {
      padding-left: 27px;
    }

    &__question p {
      text-decoration: underline;
      // text-decoration-style: dashed;
      border-bottom: none;
    }
  }
}
