.control {
  @include reset-button;

  &:hover,
  &:focus {
    outline: none;
  }

  &--gallery {
    width: 47px;
    height: 47px;

    background-color: transparent;
    background-repeat: no-repeat;
    background-position: center center;

    border: 1px solid $color-light-grey;
    border-radius: 4px;

    overflow: hidden;
  }

  &--faq,
  &--faq-close {
    position: relative;
    width: 50px;
    height: 50px;
    background-color: transparent;
    border: 1px solid $color-blue;
    border-radius: 4px;

    &::before,
    &::after {
      @include pseudo;
      left: 50%;
      top: 50%;
      margin-left: -9px;
      margin-top: -1px;

      width: 19px;
      height: 3px;
      background-color: $color-blue;

      // transition: transform 0.3s;
    }

    &::after {
      transform: rotate(90deg);
    }
  }

  &--faq-close {
    &::before {
      transform: rotate(-45deg);
    }

    &::after {
      transform: rotate(45deg);
    }
  }

  svg {
    fill: $color-light-grey;
    margin: 15px;
  }

  &--modal-close {
    position: absolute;
    right: 49px;
    top: 50%;
    margin-top: -21px;

    width: 41px;
    height: 41px;

    &::before,
    &::after {
      @include pseudo;
      top: 50%;
      left: 50%;
      margin-top: -1px;
      margin-left: -14px;

      width: 28px;
      height: 3px;

      background-color: $color-dark-grey;
    }

    &::before {
      transform: rotate(-45deg);
    }

    &::after {
      transform: rotate(45deg);
    }
  }
}

// @media (min-width: $tablet-width) {
  .control:hover,
  .control:focus {
    opacity: 0.7;
    outline: none;
  }

  .control:active {
    opacity: 1;
  }
// }
