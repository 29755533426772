.overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 50;

  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;

  background-color: rgba(0, 0, 0, 0.4);

  &--show {
    display: block;
  }
}
