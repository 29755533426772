.partners {
  padding-bottom: 80px;

  .inner-page__column--right {
    margin-top: -7px;
  }

  &__menu {
    margin-bottom: 8px;
  }

  &__menu-card {
    margin-bottom: 8px;
  }

  .partners__desc {
    margin-bottom: 50px;
  }

  &__tab {
    .tab__content {
      .page-title--h2 {
        margin-bottom: 20px;
      }

      & > p {
        // height: 60px;
        margin: 0;
        padding: 0;
        margin-bottom: 35px;

        // @include cut-text(2);
      }
    }
  }

  &__list {
    .partners__item {
      margin-bottom: 32px;
    }
  }

  @include mobile {
    padding-bottom: 25px;

    .partners__desc {
      margin-bottom: 30px;
    }

    &__list {
      .partners__item {
        margin-bottom: 20px;
      }
    }
  }
}
