.modal {
  position: fixed;
  top: 0;
  left: 50%;

  width: 100%;

  z-index: 100;

  transform: translateX(-50%) translateY(-50%) scaleY(0);
  transition: 0.3s;

  &--hidden {
    display: none;
  }

  &--show {
    transform: translateX(-50%) translateY(0px) scaleY(1);
    transition: 0.3s;
  }

  &--gallery {
    top: 100px;
  }
}

@include tablet {
  .open-menu.modal {
    transform: translateX(-50%) translateY(0) scaleX(0);
  }

  .open-menu.modal--show {
    transform: translateX(0) translateY(0) scaleX(1);
  }
}
