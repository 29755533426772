.gallery {
  width: 100%;
  position: relative;

  &__title {
    margin-bottom: 25px;
  }

  &__inner {
    width: 77.4%;
    max-width: 950px;
    margin: 0 auto;
    overflow: hidden;
  }

  &__control {
    position: absolute;
    top: 50%;
    margin-top: 3px;
    z-index: 1;
  }

  .control--left {
    left: 3%;
  }

  .control--right {
    right: 3%;
  }

  &__list {
    @include reset-list;
    display: flex;
  }

  &__item {
    flex-shrink: 0;
  }

  // &__media-wrap {
    img {
      display: block;
      object-fit: cover;
    }
  // }

  &--modal {
    width: 100%;
    max-width: 800px;
    padding-top: 25px;
    padding-bottom: 25px;
    background-color: $color-white;
    box-shadow: 0 0 12px 12px $color-menu-shadow;

    .gallery__control--modal-close {
      margin: 0;
      top: 20px;
      right: 35px;
    }

    img {
      width: 100%;
    }
  }

  @include mobile {
    &__inner {
      width: 100%;
    }

    &__list {
      width: 100%;
    }

    // &__control {
    //   border-color: $color-white;

    //   svg {
    //     fill: $color-white;
    //   }
    // }

    &--modal {
      padding: 0;
    }

    .gallery__control--modal-close {
      top: 10px;
      right: 10px;

      // &::before,
      // &::after {
      //   background-color: $color-white;
      // }

    }
  }
}
