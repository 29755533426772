.open-menu {
  width: 100%;
  height: 100%;

  overflow-x: hidden;
  overflow-y: auto;

  background-color: white;
  box-shadow: 0 13px 12px $color-menu-shadow;
}

.open-menu__wrapper {
  padding-top: 40px;
  padding-bottom: 90px;
}

.open-menu__header {
  display: flex;
  justify-content: space-between;

  padding-bottom: 20px;
}

.open-menu__header--menu {
  display: none;
}

.open-menu__logo {
  letter-spacing: -0.6px;
  margin-right: 14.4%;
}

.open-menu__central-header-block {
  display: flex;
  margin-right: auto;
  align-items: center;
}

.open-menu__search-wrapper {
  position: relative;
}

.open-menu__search {
  width: 260px;
  padding: 7px;

  border: 2px solid #d8d8d8;
  border-radius: 5px;
}

.open-menu__button-search {
  @include reset-button;
  position: absolute;
  top: 2px;
  right: 12px;

  height: 24px;

  cursor: pointer;

  svg {
    margin-top: 3px;
  }

  &:hover,
  &:focus {
    opacity: 0.7;
    outline: 0;
  }
}

.open-menu__list-language {
  @include reset-list;
  position: relative;

  margin-left: 13px;

  font-size: 13px;
  font-weight: 700;

  svg {
    position: absolute;
    top: 7px;
    right: 0;

    fill: $color-dark-grey;
  }
}

.open-menu__list-language-link {
  padding-right: 15px;

  text-decoration: none;
  color: $color-dark-grey;

  &:hover,
  &:focus {
    opacity: 0.7;
    outline: 0;
  }
}

.open-menu__link-change-city {
  display: none;
}

.open-menu__button-close {
  @include reset-button;

  position: relative;

  width: 30px;
  height: 30px;

  &::before,
  &::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 3px;

    display: block;
    width: 25px;
    height: 3px;

    background-color: $color-dark-grey;
  }

  &::before {
    transform: rotate(-45deg);
  }

  &::after {
    transform: rotate(45deg);
  }
}

.open-menu__container {
  display: flex;
  padding-top: 30px;
}

.open-menu__column {
  &--left {
    width: 76%;
    border-right: 1px solid $color-open-menu-border;
  }

  &--right {
    width: 24%;
    padding-left: 45px;

    display: flex;
    flex-direction: column;
  }
}

.open-menu__contacts {
  // width: 100%;
  min-width: 185px;
  margin-bottom: 50px;
}

.open-menu__buttons {
  width: 172px;

  .button {
    margin-bottom: 15px;
    padding-top: 7px;
    padding-bottom: 7px;

    font-size: 13px;
    line-height: 18px;
  }
}

.open-menu__social {
  width: 100%;
  min-width: 135px;
  margin-top: auto;
}

.open-menu__list {
  @include reset-list;

  display: flex;
  flex-wrap: wrap;

}

.open-menu__item {
  width: 25.5%;
  margin-right: 7.7%;
  margin-bottom: 30px;

  font-size: 15px;
  line-height: 20px;

  &:nth-child(3n) {
    margin-right: 0;
  }

  & > svg {
    display: none;
  }

  & > a {
    display: block;
    margin-bottom: 20px;

    font-weight: 700;

    color: $color-dark-grey;
  }

  .menu__item {
    position: relative;
    margin-bottom: 20px;
    padding-right: 13px;

    & > a {
      color: $color-grey;
    }
  }

  .menu__arrow {
    position: absolute;
    right: 0;
    top: 50%;
    margin-top: -6px;

    svg {
      display: block;
      fill: $color-dark-grey;
      color: transparent;
    }
  }
}

@media (max-width: $tablet-max-width) {
  .open-menu {
    width: 302px;
    left: 0;

    scrollbar-color: $color-blue $color-white;
    scrollbar-width: thin;

    border: 1px solid $color-open-menu-border;

    &--inner-menu-opened {
      overflow: hidden;
    }
  }

  .open-menu::-webkit-scrollbar {
    width: 4px;
    background-color: $color-white;
  }

  .open-menu::-webkit-scrollbar-thumb {
    background-color: $color-blue;
    border-radius: 4px;
  }

  .open-menu__wrapper {
    padding: 0;
  }

  .open-menu__header {
    padding: 20px;
    padding-top: 18px;
    padding-right: 18px;

    align-items: center;

    border-bottom: 1px solid $color-open-menu-border;
  }

  .open-menu__logo {
    span {
      font-size: 20px;
      line-height: 27px;
    }
  }

  .open-menu__central-header-block {
    display: none;
  }

  .open-menu__button-close {
    width: 24px;
    height: 24px;

    &::before,
    &::after {
      width: 22px;
    }
  }

  .open-menu__container {
    width: 100%;
    padding: 0;

    flex-direction: column;
  }

  .open-menu__column {
    width: 100%;
    border: none;
    padding: 0;

    &--left {
      border-bottom: 1px solid $color-open-menu-border;

    }

    &--right {
      border-bottom: 1px solid $color-open-menu-border;
    }
  }

  .open-menu__list {
    padding: 15px 20px;

    flex-direction: column;
  }

  .open-menu__item {
    position: relative;
    width: 100%;
    margin: 0;
    padding-right: 15px;

    font-weight: 400;
    font-size: 13px;
    line-height: 18px;
    text-transform: uppercase;

    & > svg {
      position: absolute;
      right: 0;
      top: 50%;
      margin-top: -7px;

      display: block;
      transform: rotate(-90deg);

      fill: $color-open-menu-border;
    }

    & > a {
      margin: 0;
      padding: 15px 0;
      display: block;

      font-weight: 400;
    }

    .menu {
      position: fixed;
      left: 0;
      top: 0;
      // width: 302px;
      width: 100%;
      height: 100%;

      // overflow-y: auto;

      z-index: 200;
      transform: translateX(-100%);
      transition: 0.3s;

      border: 1px solid $color-open-menu-border;
      background-color: $color-white;

      scrollbar-color: $color-blue $color-white;
      scrollbar-width: thin;

      &::-webkit-scrollbar {
        width: 4px;
        background-color: $color-white;
      }

      &::-webkit-scrollbar-thumb {
        background-color: $color-blue;
        border-radius: 4px;
      }
    }

    .menu--show {
      transform: translateX(0);
      transition: 0.3s;
      overflow-y: auto;

      .menu__list--inner {
        // display: block;
      }
    }

    .menu__list {
      padding: 18px 20px;
    }

    .menu__item {
      margin: 0;

      a {
        display: block;
        padding: 12px 0;
      }

      &--with-inner {
        .menu__arrow {
          display: block;
        }
      }
    }

    .menu__arrow {
      display: none;

      svg {
        transform: rotate(-90deg);
        fill: $color-open-menu-border;
      }
    }
  }

  .open-menu__header--menu {
    display: flex;
  }

  .open-menu__button-back {
    @include reset-button;
    position: relative;
    padding-left: 27px;
    padding-top: 8px;
    padding-bottom: 8px;

    font-size: 10px;
    line-height: 14px;
    text-transform: uppercase;
    letter-spacing: 6px;
    color: $color-grey;

    &::before {
      @include pseudo;
      left: 0;
      top: 50%;
      margin-top: -12px;
      width: 24px;
      height: 24px;
      background-repeat: no-repeat;
      background-position: center center;
      background-image: url("../img/menu-dropdown/sharp-arrow_back-24px.svg");
    }
  }

  .open-menu__buttons {
    display: none;
  }

  .open-menu__link-change-city {
    padding-left: 25px;

    display: block;
    font-size: 13px;
    line-height: 18px;
    color: $color-blue;

    a {
      display: inline-block;
      color: inherit;
      border-bottom: 1px dashed $color-blue;
    }
  }

  .open-menu__wrap {
    padding: 32px 20px;

    border-bottom: 1px solid $color-open-menu-border;
  }

  .open-menu__contacts {
    margin-bottom: 8px;

    .contacts__item {
      margin: 0;
      margin-bottom: 21px;
      padding-left: 25px;

      line-height: 18px;

      &::before {
        width: 16px;
        height: 16px;

        background-size: cover;
      }

      &:last-child {
        margin-bottom: 0;

        line-height: 22px;
      }

      svg {
        top: 8px;
        fill: $color-open-menu-border;
        transform: rotate(-90deg);
      }
    }
  }

  .open-menu__social {
    padding: 25px 20px;

    .social__item {
      margin-bottom: 16px;
    }
  }
}

//  ===================== темная тема мобильной версии меню ======================

@media (max-width: $tablet-max-width) {
  .open-menu--dark {
    color: $color-white;
    background-color: $color-dark-grey;
    border-color: $color-dark-grey;

    .open-menu__column--right {
      border-color: $color-dark-grey;
    }

    .open-menu__button-close::before,
    .open-menu__button-close::after {
      background-color: $color-white;
    }

    .open-menu__item a,
    .menu__item a {
        color: $color-white;
    }

    .menu {
      background-color: $color-dark-grey;
    }

    .logo {
      background-image: url("../img/logo-white.png");
    }

    .contacts__item,
    .social h2 {
      color: $color-white;
    }

    .contacts__list--show {
      background-color: $color-dark-grey;
    }
  }
}

// ===================== ховеры =====================
// @media (min-width: $tablet-max-width) {
  .open-menu__item {
    .menu__item a:hover,
    .menu__item a:focus {
      color: $color-blue;
    }
  }

  .open-menu__button-close:hover,
  .open-menu__button-close:focus {
    opacity: 0.7;
    outline: none;
  }
  .open-menu__button-close:active {
    opacity: 1;
  }
// }

@media (min-width: $tablet-max-width) {
  .open-menu {
    .menu__list--inner {
      position: absolute;
      left: 100%;
      top: -25px;

      padding: 25px 50px;

      display: none;
      background-color: $color-white;
      box-shadow: 0 13px 12px $color-menu-shadow;

      z-index: 300;
    }

    .menu__item--with-inner:hover,
    .menu__item--with-inner:focus {
      // background-color: $color-menu-item-hover-bg;
  
      & > a {
        color: $color-blue;
      }
  
      .menu__arrow svg {
        fill: $color-blue;
        transform: rotate(-90deg);
      }
  
      .menu__list--inner {
        display: block;
      }
    }  
  }
}
// @media (min-width: $tablet-width) {
  // .open-menu__item:hover,
  // .open-menu__item:focus {
  //   & > a {
  //     color: $color-blue;
  //   }

  //   & > svg {
  //     fill: $color-blue;
  //   }
  // }


// }
