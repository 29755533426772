.list {
  width: 100%;

  &__items {
    @include reset-list;
  }

  &__title {
    margin-bottom: 22px;
  }

  &--paragraph {
    width: 90%;

    .list__item {
      position: relative;
      margin-bottom: 30px;
      padding-left: 54px;

      &:last-child {
        margin-bottom: 0;
      }

      &::before {
        @include pseudo;
        top: 8px;
        left: 21px;
        width: 15px;
        height: 15px;
        background-repeat: no-repeat;
        background-position: center center;
        background-image: url("../img/sharp-check_circle_outline-24px.svg");
      }
    }

    p {
      margin: 0;
      padding: 0;

      font-size: 16px;
      line-height: 30px;
      color: $color-light-grey;
    }
  }

  &--card {
    .list__items {
      display: flex;
      flex-wrap: wrap;

      align-items: stretch;

      .list__item {
        margin-right: 1.3%;
        margin-bottom: 1.3%;

        &:nth-child(3n) {
          margin-right: 0;
        }
      }
    }

    .list__item {
      width: 32.4%;
    }
  }

  &--card-fullsize {
    .list__item {
      margin-bottom: 40px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

@media (max-width: 1050px) {
  .list--card .list__item {
    width: 49%;
  }

  .list--card .list__items {
    .list__item {
      margin-right: 2%;
      margin-bottom: 2%;

      &:nth-child(3n) {
        margin-right: 2%;
      }

      &:nth-child(2n) {
        margin-right: 0;
      }
    }
  }
}


@media (max-width: $tablet-max-width) {
  .list--paragraph {
    width: 100%;
  }
}

@media (max-width: $mobile-width) {
  .list--card {
    .list__item {
      width: 100%;
    }

    .list__items .list__item {
      margin-right: 0;

      &:nth-child(3n) {
        margin-right: 0;
      }
    }
  }

  .list--card-fullsize {
    .list__items {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
    }

    .list__item {
      margin: 0 auto;
      margin-bottom: 15px;
    }
  }
}
