.logo {
  width: 178px;
  height: 32px;

  font-size: 24px;
  font-weight: 700;
  text-decoration: none;
  color: $color-dark-grey;
  white-space: nowrap;

  background-image: url("../img/logo.svg");

  &:hover,
  &:focus {
    opacity: 0.7;
    outline: 0;
  }
}

.logo__pro {
  text-transform: uppercase;
}

.logo__24 {
  color: $color-blue;

  margin-left: -5px;
}
