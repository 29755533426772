.reviews-main {
  padding-top: 90px;
}

.reviews-main__swiper-container {
  position: relative;

  overflow: hidden;
}

.reviews-main__swiper-wrapper {
  @include reset-list;

  display: flex;
}

.reviews-main__swiper-slide {
  flex-shrink: 0;
}

.reviews-main__grey-wrapper {
  width: 100%;
  padding: 45px 100px;
  padding-bottom: 65px;

  background-color: $color-ultra-light-grey;
}

.reviews-main__content-center {
  min-height: 420px;
  max-width: 1140px;
  margin: 0 auto;
}

.reviews-main__rating-items {
  display: flex;
  justify-content: space-between;
  align-items: center;

  &::before {
    content: "";
    position: absolute;
    top: 26px;
    left: 69px;

    width: 36px;
    height: 70px;

    background-image: url("../img/quotes.svg");
  }
}

.reviews-main__date {
  margin-right: auto;
}

.reviews-main__review {
  position: relative;

  min-height: 390px;
  max-width: 1140px;
  padding: 43px 97px 31px 138px;

  background-color: $color-white;

  &::before {
    content: "";
    position: absolute;
    top: -23px;
    left: 23px;

    width: 50px;
    height: 50px;;

    background-color: $color-white;
    transform: rotate(45deg);
  }

  &::after {
    content: "";
    position: absolute;
    top: -35px;
    left: 5px;

    width: 49px;
    height: 35px;

    background-color: $color-ultra-light-grey;
  }
}

.reviews-main__reviewer {
  margin-bottom: 50px;
}

.reviews-main__reviewer-name {
  margin-bottom: 6px;

  font-size: 16px;
  letter-spacing: -0.6px;
  font-weight: 700;
  line-height: 21px;

  color: $color-dark-grey;
}

.reviews-main__reviewer-position {
  margin-top: 0;

  font-size: 14px;
  line-height: 25px;
  letter-spacing: -0.6px;

  color: $color-light-grey;
}

.reviews-main__text-header {
  margin-top: 8px;
  margin-bottom: 0;

  font-size: 15px;
  font-weight: 700;
  letter-spacing: -0.6px;
  color: $color-dark-grey;
}

.reviews-main__review-text {
  max-width: 890px;
  margin-top: 0;

  font-style: italic;
  line-height: 32px;
  letter-spacing: -0.6px;
  color: $color-dark-grey;
}

.reviews-main__star {
  margin-left: 3px;

  svg {
    fill: #bcbcbc;
  }

  &--active svg {
    fill: $color-orange;
  }
}

.reviews-main__date {
  margin-left: 14px;
  margin-bottom: 6px;

  font-size: 14px;
  line-height: 25px;
  letter-spacing: -0.6px;
}

.reviews-main__question {
  margin-right: 12px;

  font-size: 13px;
  color: $color-light-grey;
}

.reviews-main__button {
  @include reset-button;

  position: relative;

  width: 63px;
  height: 34px;
  margin-left: 8px;

  border: 1px solid $color-light-grey;
  border-radius: 5px;

  svg {
    position: absolute;
    top: 5px;
    left: 5px;
  }

  &--like {
    color: #2fcb71;

    svg {
      fill: #2fcb71;
    }
  }

  &--dislike {
    color: #e74c3c;

    svg {
      fill: #e74c3c;
      transform: rotate(180deg);
    }
  }

  &:hover,
  &:focus {
    opacity: 0.7;
    cursor: pointer;
  }
}

.reviews-main__counter {
  margin-left: 25px;

  font-size: 13px;
}

.reviews-main__swiper-button {
  position: absolute;
  top: 45%;

  width: 47px;
  height: 47px;

  border: 1px solid $color-light-grey;
  border-radius: 5px;

  svg {
    position: absolute;
    top: 16px;
    left: 18px;

    fill: $color-light-grey;
  }

  &:hover,
  &:focus {
    opacity: 0.7;
    cursor: pointer;
  }

  &--next {
    right: 36px;
  }

  &--prev {
    left: 36px;
  }
}

.reviews-main__swiper-pagination {
  position: absolute;
  bottom: 8px;
  left: 50%;

  transform: translate(-50%, -50%);

  .swiper-pagination-bullet {
    width: 12px;
    height: 12px;
    display: inline-block;
    border-radius: 100%;

    margin: 0 8px;

    vertical-align: middle;

    background-color: #c7c7c7;

    &:hover,
    &:focus {
      opacity: 0.7;
      cursor: pointer;
    }
  }

  .swiper-pagination-bullet-active {
    width: 18px;
    height: 18px;

    background-color: $color-blue;
  }
}

@media (max-width: $desktop-width) {
  .services-main {
    padding-top: 155px;
  }

  .reviews-main__question {
    display: none;
  }

  .reviews-main__review {
    padding: 43px 50px;
  }

  .reviews-main__rating-items::before {
    content: none;
  }

  .reviews-main__grey-wrapper {
    padding: 45px 75px 65px;
  }

  .reviews-main__swiper-button {

    &--prev {
      left: 0;
    }

    &--next {
      right: 0;
    }
  }
}

.reviews-main__answer {
  padding-left: 140px;
  margin-top: 33px;

  .reviews-main__review {
    min-height: 0;
    margin-bottom: 0 !important;
    padding-left: 125px;
    padding-bottom: 40px;
  }

  .reviews-main__review-text {
    margin-bottom: 0 !important;
  }
}

@media (max-width: $tablet-max-width) {
  .staff-main__swiper-button--prev {
    left: 50px;
  }

  .staff-main__swiper-button--next {
    right: 50px;
  }
}

@media (max-width: $mobile-width) {
  .reviews-main {
    padding-top: 50px;
  }

  .reviews-main__grey-wrapper {
    padding: 45px 25px 65px;
  }

  .reviews-main__date {
    display: none;
  }

  .reviews-main__button {
    display: none;
  }

  .reviews-main__review {
    padding: 43px 10px;
  }

  .reviews-main__swiper-pagination {
    bottom: 4px;

    .swiper-pagination-bullet {
      width: 6px;
      height: 6px;
      margin: 0 4px;
    }

    .swiper-pagination-bullet-active {
      width: 9px;
      height: 9px;
    }
  }

  .reviews-main__swiper-button {
    display: none;
  }
}
