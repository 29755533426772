.card-menu {
  width: 100%;
  height: 155px;

  b {
    display: block;
    font-weight: 700;
    font-size: 17px;
    line-height: 23px;
    text-transform: uppercase;
    letter-spacing: 1.6px;
  }

  a {
    position: relative;
    height: 100%;

    padding-top: 45px;
    padding-bottom: 11px;
    padding-left: 30px;
    padding-right: 50%;

    display: flex;
    flex-direction: column;

    color: $color-dark-grey;
    background-color: $color-white;

    &::before {
      @include pseudo;
      top: 0px;
      left: 50%;
      width: 0;
      height: 0;

      z-index: 1;

      border-left: 52px solid $color-white;
      border-bottom: 155px solid transparent;
    }

    &::after {
      @include pseudo;
      top: 0;
      right: 0;
      width: 50%;
      height: 100%;
      background-color: $color-card-document-hover-bg;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;
    }
  }

  &--company {
    a::after {
      background-image: url("../img/card-menu/company.jpg");

      @media (min-resolution: $retina-dpi), (min-resolution: $retina-dppx) {
        background-image: url("../img/card-menu/company@2x.jpg");
      }
    }
  }

  &--team {
    a::after {
      background-image: url("../img/card-menu/team.jpg");

      @media (min-resolution: $retina-dpi), (min-resolution: $retina-dppx) {
        background-image: url("../img/card-menu/team@2x.jpg");
      }
    }
  }

  &--feedback {
    a::after {
      background-image: url("../img/card-menu/feedback.jpg");

      @media (min-resolution: $retina-dpi), (min-resolution: $retina-dppx) {
        background-image: url("../img/card-menu/feedback@2x.jpg");
      }
    }
  }

  &--project {
    a {
      padding-top: 47px;
    }

    b {
      font-size: 13px;
      line-height: 18px;
      letter-spacing: 0.7px;
    }

    a::after {
      background-image: url("../img/card-menu/projects.jpg");

      @media (min-resolution: $retina-dpi), (min-resolution: $retina-dppx) {
        background-image: url("../img/card-menu/projects@2x.jpg");
      }
    }
  }

  &__svg {
    margin-top: auto;

    svg {
      margin-right: 10px;

      fill: $color-grey;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  // @media (min-width: $tablet-width) {
    a:hover,
    a:focus {
      color: $color-blue;

      svg {
        fill: $color-blue;
      }
    }

    a:active {
      opacity: 0.7;
    }
  }
// }
