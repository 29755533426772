.social {
  width: 100%;

  &__title {
    margin: 0;
    margin-bottom: 8px;
    padding: 0;
    font-weight: 400;
    font-size: 13px;
    line-height: 18px;
    color: $color-grey;
  }

  &__list {
    @include reset-list;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }

  &__item {
    width: 36px;
    height: 36px;
    margin-right: 8px;
    margin-bottom: 8px;
    background-repeat: no-repeat;
    background-position: center center;

    &:last-child {
      margin-right: 0;
    }

    &--facebook {
      background-image: url("../img/footer/facebook.svg");
    }

    &--instagram {
      background-image: url("../img/footer/instagram.svg");
    }

    &--skype {
      background-image: url("../img/footer/skype.svg");
    }

    &--telegram {
      background-image: url("../img/footer/telegram.svg");
    }

    &--twitter {
      background-image: url("../img/footer/twitter.svg");
    }

    &--viber {
      background-image: url("../img/footer/viber.svg");
    }

    &--vk {
      background-image: url("../img/footer/vk.svg");
    }

    &--whatsapp {
      background-image: url("../img/footer/whatsapp.svg");
    }

    &--youtube {
      background-image: url("../img/footer/youtube.svg");
    }
  }

  a {
    width: 100%;
    height: 100%;
    display: block;
  }

  &--employee {
    .social__item {
      width: 24px;
      height: 24px;
      margin: 0;
      margin-right: 16px;

      &:last-child {
        margin-right: 0;
      }

      svg {
        fill: $color-light-grey;
      }
    }
  }
}

@media (max-width: $tablet-max-width) {
  .social {
    &__title {
      padding-top: 3px;
      font-size: 14px;
      line-height: 19px;
    }
  }
}

@media (max-width: $mobile-width) {
  .social {
    &__title {
      font-size: 13px;
      line-height: 18px;
    }
  }
}

// @media (min-width: $tablet-width) {
  .social__item:hover,
  .social__item:focus {
    opacity: 0.7;
  }
  .social__item:active {
    opacity: 1;
  }
// }
