.page-title {
  margin: 0;
  padding: 0;

  font-weight: 700;
  font-size: 32px;
  line-height: 43px;
  color: $color-dark-grey;

  &--main-h2 {
    font-size: 36px;
    line-height: 48px;
  }


  &--h2 {
    font-size: 26px;
    line-height: 36px;
  }

  &--h3 {
    font-size: 20px;
    line-height: 27px;
  }

  @include mobile {
    font-size: 26px;
    line-height: 36px;

    &--h2 {
      font-size: 24px;
      line-height: 30px;
    }
  }
}
