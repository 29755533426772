.news-main {
  padding-top: 86px;
  padding-bottom: 79px;

  border-top: 1px solid #cfcfcf;
  border-bottom: 1px solid #cfcfcf;
}

.news-main__press-wrapper {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-left: 0
}

.news-main__list-item {
  @include reset-list;

  width: 24.5%;
  margin-right: 0.5%;
  margin-bottom: 1.3%;

  &:last-child {
    margin-right: 0;
  }
}

@media (max-width: $desktop-width) {
  .news-main {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .news-main__list-item {
    width: 49%;
    margin-right: 0.5%;

    &:nth-child(2) {
      margin-right: 0;
    }
  }
}

@media (max-width: $tablet-width) {
  .news-main__list-item {
    width: 100%;
    margin-right: 0;

    img {
      width: 100%;
    }
  }

  .news-main__list-item {
    max-width: none;
    width: 100%;

    .card-news {
      max-width: none;
    }
  }
}
