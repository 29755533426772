.menu-card {
  width: 100%;
  padding: 8px;

  background-color: $color-ultra-light-grey;
  border: 1px solid $color-super-light-grey;

  &__list {
    @include reset-list;
  }

  &__item {
    margin-bottom: 6px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}
