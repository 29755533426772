.subscribe-form {
  width: 350px;
  // max-width: 350px;

  font-family: $font-main;
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  color: $color-grey;

  &__wrap {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  &__button {
    width: 116px;
    padding-top: 7px;
    padding-bottom: 7px;
    font-size: 13px;
    line-height: 18px;
  }

  form {
    width: 100%;
  }

  input {
    width: 230px;
    padding: 7px 16px;
    color: inherit;
    font: inherit;
    border: 1px solid $color-input-border;
    border-radius: 3px;

    appearance: none;
  }

  label {
    margin-bottom: 5px;
    display: block;
  }
}

@media (max-width: $tablet-max-width) {
  .subscribe-form {
    width: 325px;

    label {
      font-size: 14px;
      line-height: 19px;
    }

    input {
      width: 200px;
    }
  }
}

@media (max-width: $mobile-width) {
  .subscribe-form {
    width: 100%;
    max-width: 350px;

    input {
      width: calc(90% - 90px);
    }

    label {
      font-size: 13px;
      line-height: 18px;
    }

    &__button {
      width: 35%;
      min-width: 110px;
    }
  }
}

// @media (min-width: $tablet-width) {
  .subscribe-form input:hover {
    border-color: $color-blue;
  }

  .subscribe-form input:focus {
    border-color: $color-blue;
  }
// }
