@mixin mobile {
  @media (max-width: $mobile-width) {
    @content;
  }
}

@mixin tablet {
  @media (max-width: $tablet-max-width) {
    @content;
  }
}

@mixin reset-list {
  margin: 0;
  padding: 0;
  list-style: none;
}

@mixin reset-button {
  border: none;
  margin: 0;
  padding: 0;
  width: auto;
  background-color: transparent;
  color: inherit;
  font: inherit;
  cursor: pointer;
  text-align: center;
  vertical-align: middle;
}

@mixin pseudo ($display: block, $pos: absolute, $content: "") {
  content: $content;
  display: $display;
  position: $pos;
}

@mixin input-placeholder {
  &.placeholder {
    @content;
  }

  &:-moz-placeholder {
    @content;
  }

  &::-moz-placeholder {
    @content;
  }

  &:-ms-input-placeholder {
    @content;
  }

  &::-webkit-input-placeholder {
    @content;
  }
}

@mixin cut-text($line-number: 3) {
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: $line-number;
}
