.breadcrumbs {
  @include reset-list;
  display: flex;
  flex-wrap: wrap;

  &__item {
    position: relative;
    margin-right: 11px;
    font-size: 13px;
    line-height: 18px;
    color: $color-light-grey;

    a {
      margin: 0;
      display: inline-block;
      color: inherit;
    }

    &--current {
      margin-right: 0;
    }

    &::after {
      @include pseudo($content: "/");
      top: 0;
      right: -7px;
    }

    &:last-child::after {
      display: none;
    }
  }
}

// @include mobile {
//   .breadcrumbs {
//     // width: 80%;
//   }
// }

// ховеры
// @media (min-width: $tablet-width) {
  .breadcrumbs__item:hover,
  .breadcrumbs__item:focus {

    a[href] {
      color: $color-blue;
      text-decoration: underline;
    }
  }
// }
