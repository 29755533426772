.search-form {
  width: 100%;
  max-width: 1088px;

  form {
    width: 100%;
    display: flex;
  }

  input {
    width: 916px;
    padding: 14px 20px;
    margin-right: 10px;

    font-size: 15px;
    line-height: 20px;
    color: inherit;

    appearance: none;

    border: 1px solid $color-grey;
    border-radius: 3px;
    background-color: transparent;
  }

  &__button {
    position: relative;
    width: 161px;
    padding: 14px 20px;
    padding-left: 45px;
    margin-left: auto;

    flex-shrink: 0;

    font-size: 16px;
    line-height: 22px;

    &::before {
      @include pseudo;
      width: 24px;
      height: 24px;
      left: 32px;
      top: 50%;
      margin-top: -12px;
      background-repeat: no-repeat;
      background-position: center center;
      background-image: url("../img/magnifier.svg");
    }

    svg {
      display: none;
    }
  }

  &--open-menu {
    position: relative;

    input {
      width: 260px;
      padding: 7px;

      border: 2px solid #d8d8d8;
      border-radius: 5px;

      -webkit-appearance: none;

      &::-webkit-search-cancel-button {
        display: none;
      }
    }

    .search-form__button {
      @include reset-button;
      position: absolute;
      top: 2px;
      right: 12px;

      height: 24px;

      cursor: pointer;

      svg {
        margin-top: 3px;
        display: block;
      }

      &::before,
      span {
        display: none;
      }

      &:hover,
      &:focus {
        opacity: 0.7;
        outline: 0;
      }
    }
  }
}

@media (max-width: $tablet-width) {
  .search-form {
    input {
      margin: 0;
      padding-left: 6px;
      padding-right: 6px;
      width: 77%;
    }

    &__button {
      width: 18%;
      padding-left: 6px;
      padding-right: 6px;

      &::before {
        left: 50%;
        margin-left: -12px;
      }
      span {
        display: none;
      }
    }
  }
}

// @media (min-width: $tablet-width) {
  .search-form input:hover {
    border-color: $color-blue;
  }

  .search-form input:focus {
    border-color: $color-blue;
  }
// }
