.wrapper {
  width: 100%;
  max-width: 1680px;
  margin: 0 auto;
  padding: 0 20px;

  &--header {
    padding: 0 36px;
    max-width: none;
  }

  &--no-padding {
    padding: 0;
  }

  &--inner-page {
    padding-left: 50px;
    padding-right: 50px;
  }

  &--product-card {
    max-width: 1780px;
    padding-right: 70px;
    padding-left: 70px;
  }

  @include tablet {
    padding: 0 50px;

    &--product-card {
      padding-left: 70px;
      padding-right: 70px;
    }
  }

  @include mobile {
    padding: 0 20px;
  }
}
