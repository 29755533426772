.button {
  @include reset-button;

  padding: 11px 16px;
  width: 174px;
  font-size: 14px;
  line-height: 19px;
  color: $color-blue;
  background-color: $color-white;
  border: 1px solid $color-blue;
  border-radius: 4px;

  &--blue {
    color: $color-white;
    background-color: $color-blue;
  }

  &--transparent {
    color: $color-blue;
    background-color: transparent;
  }
}

// ховеры
// @media (min-width: $tablet-width) {
  .button:hover,
  .buttom:focus {
    opacity: 0.7;
    outline: none;
  }

  .button:active {
    opacity: 1;
  }
// }
