//  ======== Карточка сотрудника =============================================

.card-employee {
  position: relative;
  width: 100%;

  padding-top: 14px;
  padding-bottom: 28px;
  padding-left: 15px;
  padding-right: 15px;

  font-size: 14px;
  line-height: 25px;

  background-color: $color-ultra-light-grey;
  border: 1px solid $color-super-light-grey;

  &__inner-wrap {
    display: flex;
  }

  &__desc {
    width: 100%;
    padding-left: 260px;
    margin-top: -20px;

    p {
      margin: 0;
      margin-bottom: 18px;
      font-size: 16px;
      line-height: 30px;
      color: $color-light-grey;
    }

    a {
      text-decoration: underline;
      letter-spacing: 0.9px;
      color: $color-blue;
    }
  }

  &__container {
    width: 75%;
    padding-top: 21px;
  }

  &__media-wrap {
    width: 245px;
    height: 237px;
    margin-right: 16px;
    flex-shrink: 0;

    img {
      display: block;
      object-fit: cover;
    }
  }

  &__name {
    margin: 0;
    padding: 0;
    margin-bottom: 17px;

    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    color: $color-dark-grey;
  }

  &__inner {
    margin-bottom: 20px;
    display: flex;

    p {
      width: 28%;
      margin: 0;
      padding: 0;
      margin-right: 2.8%;

      font-size: 14px;
      line-height: 25px;
      color: $color-light-grey;
    }
  }

  &__contacts {
    width: 28%;
    margin-right: 2.8%;
    display: flex;
    flex-direction: column;
    color: $color-dark-grey;

    a {
      text-decoration: none;
      letter-spacing: normal;
      color: inherit;
    }
  }

  &__social {
    width: 28%;
    padding-top: 5px;
  }

  &__button {
    display: block;
    width: 187px;
    margin-bottom: 50px;

    padding-top: 7px;
    padding-bottom: 7px;

    font-size: 13px;
    line-height: 18px;
    text-decoration: none;
    letter-spacing: normal;
  }

  @media (max-width: 1100px) {
    max-width: 1000px;

    &__inner-wrap {
      margin-bottom: 25px;
    }

    &__name {
      margin-bottom: 12px;
    }

    &__inner {
      flex-direction: column;
      margin-bottom: 10px;

      p {
        width: 100%;
        margin-bottom: 10px;
      }
    }

    &__contacts,
    &__social {
      width: 100%;
      margin-bottom: 10px;
    }

    &__button {
      margin: 0;
    }

    &__desc {
      padding: 0;
      margin: 0;
    }
  }

  @include mobile {
    margin-bottom: 0 !important;
    max-width: 316px;
    min-height: 650px;
    padding: 0;
    margin: 0 auto;

    &__inner-wrap {
      height: 100%;
      margin: 0;
      padding-bottom: 18px;
      flex-direction: column;
      align-items: center;
    }

    &__container {
      width: 100%;
      // height: 100%;
      flex-grow: 1;
      padding: 0 10px;
      padding-top: 30px;
      display: flex;
      flex-direction: column;
    }

    &__name {
      width: 95%;
      min-height: 54px;
      margin: 0 auto;
      margin-bottom: 8px;
      font-size: 20px;
      line-height: 27px;
      text-align: center;
    }

    &__inner {
      // height: 100%;
      margin: 0;
      display: flex;
      flex-direction: column;
      flex-grow: 1;

      p {
        min-height: 75px;
        margin: 0;
        margin-bottom: 8px;
        text-align: center;
      }

      a {
        display: inline-block;
        margin: 0 auto;
      }
    }

    &__button {
      width: 100%;
      margin-top: auto;
    }

    &__contacts {
      // margin-top: auto;
    }

    &__social {
      margin-top: auto;
      margin-bottom: 33px;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    &__media-wrap {
      width: 100%;
      max-width: 316px;
      height: auto;
      margin: 0 auto;

      img {
        object-fit: cover;
      }
    }

    &__desc {
      display: none;
    }
  }
}

//  ======== Карточка сотрудника - маленькая =============================================

.card-employee--small {
  width: 100%;
  padding: 0;

  display: flex;
  flex-direction: column;

  .card-employee__media-wrap {
    width: 100%;
    margin-right: 0;
    height: 306px;

    img {
      width: 100%;
      height: 306px;
    }
  }

  .card-employee__container {
    width: 100%;
    min-height: 335px;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: 25px 10px 12px;

    & > p,
    & > a {
      display: none;
    }

    .card-employee__button {
      display: block;
      width: 100%;
      margin: 0;
      margin-top: auto;
      background-color: transparent;
    }
  }

  .card-employee__name {
    width: 70%;
    margin: 0 auto;
    margin-bottom: 10px;

    font-size: 20px;
    line-height: 27px;
    text-align: center;
  }

  .card-employee__inner {
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    & > p {
      width: 78%;
      min-height: 75px;
      margin: 0 auto;
      margin-bottom: 26px;

      // @include cut-text(3);

      font-size: 14px;
      line-height: 25px;
      text-align: center;
      letter-spacing: -0.7px;
    }
  }

  .card-employee__social {
    width: 100%;
    margin-top: auto;

    .social__list {
      justify-content: center;
    }

    svg {
      fill: #8D9298;
    }
  }

  .card-employee__contacts {
    width: 75%;
    margin: 0 auto;
    margin-bottom: 15px;

    display: flex;
    flex-direction: column;
    align-items: center;

    font-size: 14px;
    line-height: 25px;
    text-align: center;
    letter-spacing: -0.7px;
  }
}

// @media (min-width: $tablet-width) {
  .card-employee {
    &__contacts {
      a:hover,
      a:focus {
        color: $color-blue;
        text-decoration: underline;
      }

      a:active {
        opacity: 0.7;
      }
    }

    &__desc {
      a:hover,
      a:focus {
        opacity: 0.7;      }

      a:active {
        opacity: 1;
      }
    }
  }
// }
