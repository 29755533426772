html,
body {
  height: 100%;
}

body {
  min-width: 320px;
  margin: 0;
  padding: 0;

  font-family: "Open Sans", "Arial", sans-serif;
  font-size: 16px;
  line-height: 30px;
  font-weight: 400;
  color: $color-light-grey;
  background-color: $color-white;
  overflow-x: hidden;
}

html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

a {
  text-decoration: none;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  margin: 0;
  padding: 0;
}

blockquote {
  margin: 0;
}

img {
  max-width: 100%;
  height: auto;
}

.body-modal-show {
  width: 100vw;
  overflow: hidden;

  @include mobile {
    position: fixed;
  }
}

input {
  margin: 0;
  padding: 0;
}
