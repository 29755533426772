.advantages {
  padding-top: 76px;
  padding-bottom: 55px;

  border-top: 1px solid #cfcfcf;
  border-bottom: 1px solid #cfcfcf;
}

.advantages__item-wrapper {
  display: flex;
  justify-content: space-between;
}

.advantages__item {
  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 430px;
  padding-left: 78px;
  margin-right: 10px;
}

.advantages__item-header {
  margin-bottom: 0;

  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
  text-transform: uppercase;
  color: $color-blue;
}

.advantages__item-text {
  font-size: 14px;
  line-height: 25px;
  letter-spacing: -0.6px;
}

.advantages__circle {
  position: absolute;
  top: 7px;
  left: 2px;

  width: 56px;
  height: 56px;

  background-color: $color-blue;
  border-radius: 100px;

  svg {
    position: absolute;

    fill: $color-white;
  }

  &--worker svg {
    top: 13px;
    left: 12px;
  }

  &--stopwatch svg {
    top: 11px;
    left: 14px;
  }

  &--like svg {
    top: 11px;
    left: 11px;
  }
}

@media (max-width: $desktop-width) {
  .advantages {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .advantages__item-wrapper {
    flex-direction: column;
    align-items: center;
  }

  .advantages__item {
    margin-bottom: 20px;
  }
}
