.sort {
  font-size: 15px;
  line-height: 20px;
  color: $color-grey;

  &__list {
    @include reset-list;
    display: flex;
  }

  &__item {
    position: relative;
    margin-right: 21px;
    padding-right: 19px;
    white-space: nowrap;

    &:last-child {
      margin-right: 0;
    }

    svg {
      position: absolute;
      top: 4px;
      right: 0;
      fill: $color-blue;
    }

    &--up {
      svg {
        transform: rotate(180deg);
      }
    }

    &--current {
      a {
        border-bottom: 2px solid $color-blue;
      }
    }

    a {
      display: inline-block;
      color: inherit;
    }
  }

  @include mobile {
    &__list {
      width: 60%;
      // min-width: 280px;
      // flex-direction: column;
      flex-wrap: wrap;
    }

    &__item {
      width: 48.5%;
      margin-right: 0;
      margin-bottom: 7px;

      svg {
        // position: static;
        left: 130px
      }

      &:last-child {
        margin-bottom: 0;
      }

      &:nth-child(2n + 1) {
        margin-right: 7px;
      }
    }

    @media (max-width: 650px) {
      &__list {
        width: 80%;
      }
    }

    @media (max-width: 450px) {
      &__list {
        width: 100%;
      }
    }
  }

  // @media (min-width: $tablet-width) {
    &__item:hover,
    &__item:focus {
      opacity: 0.7;
    }

    &__item:active {
      opacity: 1;
    }
  }
// }
