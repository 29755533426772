.buttons {
  display: flex;
  flex-direction: column;

  .button {
    width: 100%;
    margin-bottom: 8px;
    padding-top: 15px;
    padding-bottom: 15px;

    font-size: 16px;
    line-height: 22px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}
