.inner-page {
  padding-top: 140px;
  padding-bottom: 110px;

  font-size: 16px;
  line-height: 30px;

  &__page-title {
    margin-bottom: 35px;
  }

  &--with-left-menu {
    .inner-page__desc {
      width: 100%;
      margin-bottom: 40px;

      &--card {
        margin-bottom: 65px;
      }
    }
  }

  &__desc {
    width: 75%;
    margin-bottom: 60px;

    p {
      margin: 0;
      margin-bottom: 25px;
      padding: 0;

      color: $color-light-grey;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__wrap {
    display: flex;
    justify-content: space-between;
  }

  &__column {
    &--left {
      width: 20.8%;
      min-width: 300px;
      margin-right: 40px;
    }

    &--left-catalog {
      min-width: 340px;
      margin-right: 75px;
    }

    &--right {
      width: 74.9%;
      // margin-top: -7px;
    }
  }

  &__content {
    width: 100%;
    display: flex;
    flex-direction: column;

    & > p {
      width: 100%;
      margin: 0;
      margin-bottom: 30px;
      padding: 0;

      color: $color-light-grey;
    }
  }

  &__breadcrumbs {
    margin-bottom: 25px;
  }

  &__button {
    align-self: center;
    margin-bottom: 40px;
  }

  &__pagination {
    align-self: center;
  }

  @media (max-width: $breakpoint-desktop) {
    &__desc {
      width: 100%;
    }

    &__column--left:not(.inner-page__column--left-catalog) {
      display: none;
    }

    &__column--right {
      width: 100%;
    }
  }

  @include mobile {
    padding-bottom: 40px;

    &__column--left {
      min-width: 0;
    }

    &__desc {
      margin-bottom: 30px;
    }
  }
}


