.menu {
  font-family: $font-main;

  &__list {
    @include reset-list;
  }

  &__item {
    text-align: left;
  }

  &--scroll {
    overflow: auto;
    height: 100vh;
  }

  &--dropdown {
    position: absolute;
    top: calc(100% + 1px);

    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    color: $color-grey;

    background-color: $color-white;
    box-shadow: 0 13px 12px $color-menu-shadow;
    z-index: 200;

    a {
      color: inherit;
    }
  }

  &--small {
    left: -50px;
    width: 330px;
    padding: 25px 0;

    white-space: normal;

    img,
    svg {
      display: none;
    }

    .menu__arrow {
      position: absolute;
      top: 50%;
      right: 16px;
      margin-top: -6px;

      svg {
        display: block;
        fill: $color-dark-grey;
        color: transparent;
      }
    }

    .menu__item--with-inner {
      position: relative;
    }

    .menu__list--inner {
      position: absolute;
      left: 100%;
      top: -25px;

      width: 300px;
      padding: 25px 0;

      display: none;

      background-color: $color-white;
      box-shadow: 0 13px 12px $color-menu-shadow;
      z-index: 100;
    }

    .menu__item {
      a {
        display: block;
        padding: 10px 50px;
        text-transform: none;
      }
    }
  }

  &--fullsize {
    width: 100%;
    left: 0;
    padding: 30px 0;

    .menu__list {
      margin-bottom: -45px;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;

      &--all {
        width: 85%;
        margin: 0 auto;
      }

      &--inner {
        margin-bottom: 0;
        flex-direction: column;
      }
    }

    .menu__item {
      position: relative;
      margin-right: 25px;
      margin-bottom: 30px;
      white-space: normal;
      word-break: break-word;

      &--with-inner {
        width: 22.4%;
        margin-right: 3%;
        min-width: 290px;

        &:nth-child(4n) {
          margin-right: 0;
        }

        @media (max-width: 1500px) {
          &:nth-child(4n) {
            margin-right: 3%;
          }

          &:nth-child(3n) {
            margin-right: 0;
          }
        }

        @media (max-width: 1090px) {
          &:nth-child(3n) {
            margin-right: 3%;
          }

          &:nth-child(2n) {
            margin-right: 0;
          }
        }

        a {
          display: inline-block;
          padding: 10px 0;
          text-transform: none;
        }

        & > a {
          display: inline-block;
          margin: 0;
          margin-bottom: 10px;
          padding: 0;
          padding-left: 7px;
          font-weight: 700;
          font-size: 15px;
          letter-spacing: -0.7px;
        }
      }

      &--inner {
        margin: 0;
        margin-right: 0;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  &--with-image-jpg {
    .menu__item {
      padding-left: 95px;

      &--inner {
        padding-left: 7px;
      }

      img {
        position: absolute;
        top: 0;
        left: 0;
      }

      svg {
        display: none;
      }
    }

    .menu__arrow {
      display: none;
    }
  }

  &--with-image-svg {
    .menu__item {
      padding-left: 75px;

      &--inner {
        padding-left: 7px;
      }

      img {
        display: none;
      }

      svg {
        position: absolute;
        top: -6px;
        left: 0;
      }
    }

    .menu__arrow {
      display: none;
    }
  }

  &--without-image {
    .menu__item--inner {
      padding-left: 7px;
    }

    img,
    svg {
      display: none;
    }
  }

  &--short {
    .menu__item {
      padding-left: 82px;

      &--with-inner {
        margin-bottom: 60px;
      }
    }

    img {
      display: none;
    }

    svg {
      position: absolute;
      top: -6px;
      left: 0;
    }

    .menu__list--inner {
      display: none;
    }

    .menu__arrow {
      display: none;
    }
  }

  &--left {
    width: 100%;

    .menu__list {
      border: 1px solid $color-super-light-grey;
      background-color: $color-ultra-light-grey;

      &--inner {
        padding-top: 15px;
        padding-left: 13px;
        list-style-type: disc;
        border: none;
      }
    }

    .menu__item {
      padding: 19.5px 35px;

      border-bottom: 1px solid $color-super-light-grey;

      font-size: 15px;
      line-height: 20px;
      color: $color-grey;

      &:last-child {
        border-bottom: none;
      }

      &--current {
        font-weight: 700;
        color: $color-dark-grey;
      }

      a {
        // display: block;
        color: inherit;
      }

      &--with-inner {
        position: relative;

        svg {
          position: absolute;
          right: 22px;
          top: 24px;

          fill: $color-blue;
        }
      }

      &--with-inner-closed {
        .menu__list--inner {
          display: none;
        }
      }

      &--with-inner-opened {
        .menu__list--inner {
          display: block;
        }

        svg {
          transform: rotate(180deg);
        }
      }

      &--inner {
        padding: 0;
        margin-bottom: 16px;

        font-size: 13px;
        line-height: 18px;

        border: none;
        color: $color-blue;

        &:last-child {
          margin-bottom: 0;
        }

        & > a {
          color: $color-grey;
        }
      }
    }
  }

  &--product-card {
    position: relative;

    .menu__control {
      width: 24px;
      height: 24px;

      top: 38px;
      right: 33px;

      display: none;
    }

    .menu__item--name {
      font-weight: 700;
      color: $color-white;
      background-color: $color-blue;
    }
  }

  &--product-card-closed {
    display: none;
  }

  &--footer {
    font-size: 13px;
    line-height: 18px;
    color: $color-grey;

    .menu__title {
      margin: 0;
      margin-bottom: 16px;
      padding: 0;
      font-weight: 700;
      font-size: inherit;
      color: $color-dark-grey;
      text-transform: uppercase;
      letter-spacing: 0.8px;
      word-break: break-word;
    }

    a {
      color: inherit;
    }

    .menu__item {
      margin-bottom: 16px;
      word-break: break-word;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &--services {
    width: 230px;
  }

  &--production {
    width: 195px;
  }

  &--about {
    width: auto;
  }

  &--main {
    width: 120px;

    a {
      font-weight: 700;
      color: $color-dark-grey;
      text-transform: uppercase;
      letter-spacing: 0.8px;
    }
  }
}

@media (max-width: 1187px) {
  .menu--product-card {
    .menu__control {
      display: block;
    }

    .menu__item--name {
      color: $color-blue;
      background-color: $color-ultra-light-grey;
      text-align: center;

      border-bottom: 1px solid $color-super-light-grey;
    }
  }
}

@media (max-width: $tablet-max-width) {
  .menu--footer {
    font-size: 14px;
    line-height: 19px;
  }
}

@media (max-width: $mobile-width) {
  .menu--footer {
    font-size: 13px;
    line-height: 18px;
  }
}

// ховеры, исключая мобильные
// @media (min-width: $tablet-width) {
  .menu {
    &--left {
      .menu__item {
        a[href]:hover,
        a[href]:focus {
          color: $color-blue;
          text-decoration: underline;
        }

        a[href]:active {
          opacity: 0.7;
        }
      }
    }

    &--footer {
      .menu__item a:hover,
      .menu__item a:focus {
        color: $color-blue;
        text-decoration: underline;
      }

      .menu__item a:active {
        opacity: 0.7;
      }
    }

    &--dropdown {
      .menu__item:hover,
      .menu__item:focus {
        background-color: $color-menu-item-hover-bg;

        & > a {
          color: $color-blue;
        }

        & > .menu__arrow svg {
          fill: $color-blue;
        }
      }
    }

    &--fullsize {
      .menu__item--with-inner:hover,
      .menu__item--with-inner:focus {
        background-color: transparent;

        & > a {
          color: inherit;
        }
      }

      .menu__item--with-inner a:hover,
      .menu__item--with-inner a:focus {
        color: $color-blue;
      }
    }

    &--small {
      .menu__item--with-inner:hover,
      .menu__item--with-inner:focus {
        & > .menu__arrow svg {
          transform: rotate(-90deg);
        }

        & > .menu__list--inner {
          display: block;
        }
      }
    }
  }
// }
