//  ======== Карточка публикации =============================================

.card-publication {
  min-height: 357px;

  a {
    position: relative;
    padding-bottom: 35px;
    height: 100%;
    display: flex;
    flex-direction: column;
    background-color: $color-ultra-light-grey;
    border: 1px solid $color-super-light-grey;

    &::after {
      @include pseudo;
      bottom: 9px;
      right: 11px;
      width: 24px;
      height: 24px;
      background-repeat: no-repeat;
      background-position: center center;
      background-image: url("../img/sharp-arrow_back-24px.svg");
    }
  }

  &__type {
    padding: 12px 20px;

    position: absolute;
    top: 14px;
    left: 0;

    font-size: 14px;
    line-height: 19px;
    color: $color-white;
    text-transform: uppercase;
    background-color: $color-blue;
  }

  &__media-wrap {
    height: 235px;
  }

  img {
    width: 100%;
    height: 235px;

    display: block;
    object-fit: cover;
  }

  p {
    margin: 0;
    padding: 29px 25px 0;

    // @include cut-text(2);

    font-weight: 600;
    font-size: 16px;
    line-height: 26px;
    color: $color-dark-grey;
  }
}

// @media (min-width: $tablet-width) {
  .card-publication:hover,
  .card-publication:focus {
    a {
      background-color: $color-card-service-hover;
    }
  }
  .card-publication:active {
    opacity: 0.7;
  }
// }
