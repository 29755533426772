.banner-slider {
  position: relative;
}

.banner-slider__swiper-container {
  position: relative;

  margin: 0 auto;
  max-width: 100%;

  overflow: hidden;
}

.banner-slider__swiper-wrapper {
  @include reset-list;

  position: relative;

  display: flex;
  height: 680px;
}

.banner-slider__swiper-slide {
  position: relative;
  max-width: 100%;
  width: 100%;
  height: 100%;
  flex-shrink: 0;

  background-position: center;
  background-size: cover;

  &:first-child {
    background-image: url("../img/main-page/banner-1.jpg");

    @media (min-resolution: $retina-dpi),
    (min-resolution: $retina-dppx) {
      background-image: url("../img/main-page/banner-1@2x.jpg");
    }
  }

  &:nth-child(2) {
    background-image: url("../img/main-page/banner-2.jpg");

    @media (min-resolution: $retina-dpi),
    (min-resolution: $retina-dppx) {
      background-image: url("../img/main-page/banner-2.jpg");
    }
  }

  &:nth-child(3) {
    background-image: url("../img/main-page/banner-3.jpg");

    @media (min-resolution: $retina-dpi),
    (min-resolution: $retina-dppx) {
      background-image: url("../img/main-page/banner-3.jpg");
    }
  }

  &:nth-child(4) {
    background-image: url("../img/main-page/banner-4.jpg");

    @media (min-resolution: $retina-dpi),
    (min-resolution: $retina-dppx) {
      background-image: url("../img/main-page/banner-4.jpg");
    }
  }

  &:nth-child(5) {
    background-image: url("../img/main-page/banner-1.jpg");

    @media (min-resolution: $retina-dpi),
    (min-resolution: $retina-dppx) {
      background-image: url("../img/main-page/banner-1@2x.jpg");
    }
  }
}

.banner-slider__banner-text-block {
  margin-top: 233px;
  margin-left: 140px;
}

.banner-slider__swiper-button {
  position: absolute;
  top: 48%;

  width: 47px;
  height: 47px;

  border: 1px solid $color-white;
  border-radius: 5px;

  svg {
    position: absolute;
    top: 16px;
    left: 18px;

    fill: $color-white;
  }

  &:hover,
  &:focus {
    opacity: 0.7;
    cursor: pointer;
  }

  &--next {
    right: 20px;
  }

  &--prev {
    left: 20px;
  }
}

.banner-slider__swiper-pagination {
  position: absolute;
  bottom: 17px;
  left: 50%;

  transform: translate(-50%, -50%);

  .swiper-pagination-bullet {
    display: inline-block;
    width: 12px;
    height: 12px;
    margin: 0 8px;

    vertical-align: middle;

    background-color: $color-white;
    border-radius: 100%;

    &:hover,
    &:focus {
      opacity: 0.7;
      cursor: pointer;
    }
  }

  .swiper-pagination-bullet-active {
    width: 18px;
    height: 18px;

    background-color: $color-blue;
  }
}

.banner-slider__banner-title {
  margin-bottom: 33px;

  font-size: 46px;
  line-height: 62px;
  font-weight: 700;
  color: $color-white;
  letter-spacing: -2.2px;
  max-width: 650px;

  &--company {
    max-width: 710px;
  }

  &--vacancy {
    color: $color-dark-grey;
  }
}

.banner-slider__banner-text {
  margin-bottom: 33px;

  font-size: 18px;
  line-height: 24px;
  color: $color-white;
  letter-spacing: -0.8px;
  max-width: 650px;

  &--company {
    max-width: 721px;
  }

  &--vacancy {
    color: $color-dark-grey;
    max-width: 650px;
  }
}

.banner-slider__button {
  display: block;
  width: 161px;
  padding-top: 16px;
  padding-bottom: 15px;

  font-size: 16px;
  letter-spacing: -0.8px;
}

@media (max-width: 1600px) {
  .banner-slider__banner-text-block {
    margin-left: 110px;
  }

  .banner-slider__banner-text-block {
    width: 600px;
  }
}

@media (max-width: 1400px) {
  .banner-slider__banner-text-block {
    width: 530px;
    margin-top: 250px;
  }

  .banner-slider__banner-title {
    margin-bottom: 15px;
    font-size: 42px;
    line-height: 52px;
  }

  .banner-slider__banner-text {
    margin-bottom: 22px;
    font-size: 16px;
    line-height: 22px;
  }

  .banner-slider__button {
    font-size: 16px;
    line-height: 22px;
  }
}

@media (max-width: $mobile-width) {
  .banner-slider__banner-title {
    font-size: 32px;
    line-height: 42px;
  }

  .banner-slider__banner-text {
    margin-bottom: 22px;
    font-size: 14px;
    line-height: 18px;
  }

  .banner-slider__button {
    display: block;
    width: 140px;
    padding-top: 12px;
    padding-bottom: 12px;

    font-size: 14px;
    line-height: 18px;
  }

  .banner-slider__banner-text-block {
    width: auto;
    max-width: 500px;
    margin: 250px auto 0;
    padding: 0 20px;
  }

  .banner-slider__banner-text {
    max-width: none;
  }

  .banner-slider__swiper-button {
    display: none;
  }
}

@media (max-width: $mobile-width) {
  .banner-slider__banner-text-block {
    margin-top: 200px;
  }
}
