.header {
  position: fixed;
  z-index: 1;

  width: 100%;

  &--short {
    .header__items,
    .header__city,
    .header__button-search,
    .header__language {
      display: none;
    }

    .header__main-panel-wrapper {
      height: 64px;
    }

    .header__button-burger {
      margin: 20px 0 30px;
    }
  }
}

.header__main-panel-wrapper {
  position: relative;
  display: flex;
  height: 99px;
  border-bottom: 1px solid #d2d2d2;
}

.header__top-panel {
  display: none;
}

.header__content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.header__button-burger {
  @include reset-button;

  margin: 38px 0 30px;

  cursor: pointer;

  svg {
    fill: $color-dark-grey;
  }

  &:hover,
  &:focus {
    opacity: 0.7;
    outline: 0;
  }
}

.header__logo {
  margin-left: 37px;
  margin-right: 30px;
}

.header__items {
  @include reset-list;

  display: flex;
  height: 100%;
  margin-right: auto;

  font-size: 13px;
  font-weight: 700;
  text-transform: uppercase;
}

.header__item {
  width: 100%;
  height: 100%;
  margin: 0 16px;
  padding: 34px 0;

  white-space: nowrap;
  letter-spacing: 0.6px;

  .menu--dropdown {
    display: none;
  }
}

.header__item:not(.header__item--dots)::after {
  content: "";
  position: relative;

  left: 0;
  bottom: -30px;

  width: 100%;
  height: 4px;
  background-color: $color-blue;

  display: none;
}

.header__item:hover,
.header__item:focus {
  .menu--dropdown {
    display: block;
  }
}

.header__item:hover::after,
.header__item:focus::after {
  display: block;
}

.header__item--small-menu {
  position: relative;
}

.header__item--dots {
  display: none;

  .menu--small {
    left: -200%;
  }
}

.header__item-link {
  padding: 37px 0;

  text-decoration: none;
  color: $color-dark-grey;

  &:hover,
  &:focus {
    outline: 0;
  }
}

.header__right-panel {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}

.header__contacts {
  display: flex;
  justify-content: space-between;
  margin-right: 20px;
}

.header__city {
  position: relative;
  z-index: 1;

  margin-right: 20px;

  font-size: 13px;
  letter-spacing: 0.6px;
  white-space: nowrap;

  svg {
    position: absolute;
    top: 10px;
    right: 0;

    fill: $color-dark-grey;
  }

  &:hover .header__city-inner {
    display: block;

    border-radius: 5px 5px 5px 5px;

    a {
      color: $color-black;
    }
  }
}

.header__city-item {
  margin-left: 3px;
}

.header__city-inner {
  @include reset-list;
  position: absolute;
  top: 0;
  left: -3px;

  display: none;

  color: $color-black;

  background-color: $color-white;
}

.header__city-link {
  padding-right: 20px;

  text-decoration: none;
  color: $color-dark-grey;

  cursor: pointer;

  &:hover,
  &:focus {
    opacity: 0.7;
    outline: 0;
  }
}

.header__list-street-link {
  text-decoration: none;
  color: $color-dark-grey;
  padding-right: 20px;

  &:hover,
  &:focus {
    opacity: 0.7;
    outline: 0;
  }
}

.header__language {
  position: relative;
  z-index: 1;

  margin-right: 20px;

  font-size: 13px;
  letter-spacing: 0.6px;
  white-space: nowrap;

  svg {
    position: absolute;
    top: 10px;
    right: 0;

    fill: $color-dark-grey;
  }

  &:hover .header__language-inner {
    display: block;
    padding-right: 2px;

    border-radius: 5px 5px 5px 5px;

    a {
      color: $color-black;
    }
  }
}

.header__language-item {
  margin-left: 3px;
}

.header__language-inner {
  @include reset-list;
  position: absolute;
  top: 0;
  left: -3px;

  display: none;

  color: $color-black;

  background-color: $color-white;
}

.header__language-link {
  padding-right: 15px;

  text-decoration: none;
  color: $color-dark-grey;

  cursor: pointer;

  &:hover,
  &:focus {
    opacity: 0.7;
    outline: 0;
  }
}

.header__language-item svg {
  left: 27px;
}

.header__number {
  position: relative;
  z-index: 1;

  font-size: 13px;
  font-weight: 700;
  letter-spacing: 0.6px;
  text-align: right;
  white-space: nowrap;

  svg {
    position: absolute;
    top: 8px;
    right: 0;

    fill: $color-dark-grey;
  }

  &:hover .header__number-inner {
    display: block;

    border-radius: 5px 5px 5px 5px;

    a {
      color: $color-black;
    }
  }
}

.header__number-item {
  margin-left: 3px;
}

.header__number-inner {
  @include reset-list;
  position: absolute;
  top: 0;
  left: -3px;

  display: none;

  padding-right: 0;

  color: $color-black;

  background-color: $color-white;
}

.header__number-link {
  text-decoration: none;
  color: $color-dark-grey;
  padding-right: 25px;

  &:hover,
  &:focus {
    opacity: 0.7;
    outline: 0;
  }
}

.header__button-call {
  padding: 0 16px;
  margin-right: 20px;

  font-size: 13px;
  text-decoration: none;
  color: $color-blue;
  white-space: nowrap;

  border: 1px solid $color-blue;
  border-radius: 3px;

  &:hover,
  &:focus {
    background-color: $color-blue;
    color: $color-white;
    outline: 0;
  }
}

.header__button-search {
  @include reset-button;

  height: 24px;
  margin-right: 20px;

  cursor: pointer;

  svg {
    margin-top: 2px;
    fill: $color-dark-grey;
  }

  &:hover,
  &:focus {
    opacity: 0.7;
    outline: 0;
  }
}

.header__language {
  @include reset-list;
  position: relative;

  margin-top: 3px;

  font-size: 13px;
  font-weight: 700;

  svg {
    position: absolute;
    top: 8px;
    right: -4px;

    fill: $color-dark-grey;
  }
}

.header__social-link {
  &:hover,
  &:focus {
    opacity: 0.7;
    outline: 0;
  }

  svg {
    vertical-align: middle;
    fill: $color-light-grey;
  }
}

.header__social-links {
  display: none;
}

.header__button-dots {
  display: none;
}

.header__address {
  display: none;
}

.header__bottom-panel {
  display: none;
}

//------------------------------------------- HEADER TYPE-1

.header--type-1 {
  @media (max-width: 1568px) {
    .header__contacts {
      flex-direction: column-reverse;
    }

    .header__city {
      margin-right: 0;
    }

    .header__number-inner {
      padding-left: 6px;
    }
  }

  @media (max-width: 1408px) {
    .header__contacts {
      position: relative;
    }

    .header__button-dots {
      @include reset-button;

      position: relative;

      display: inline-block;
      width: 30px;
      height: 30px;
      margin-right: 30px;

      background-color: transparent;

      &:hover,
      &:focus {
        opacity: 0.7;
        outline: 0;

        .menu--dropdown {
          display: block;
        }
      }

      &::before {
        content: "";
        position: absolute;
        top: 12px;
        left: 12px;

        width: 4px;
        height: 4px;

        background-color: $color-dark-grey;
        border-radius: 100px;
        box-shadow: -8px 0 $color-dark-grey, 8px 0 $color-dark-grey;
      }
    }

    .header__item--dots {
      display: block;
    }

    .header__item:nth-last-child(2),
    .header__item:nth-last-child(3) {
      display: none;
    }
  }

  @media (max-width: 1256px) {
    .header__item:nth-last-child(4),
    .header__item:nth-last-child(5) {
      display: none;
    }
  }

  @media (max-width: 1050px) {
    .header__item:first-child,
    .header__item:nth-child(2) {
      display: none;
    }
  }

  @media (max-width: 840px) {
    .header__item--dots,
    .header__button-dots {
      display: none;
    }
  }

  @media (max-width: 710px) {
    .header__main-panel-wrapper {
      height: 60px;
    }

    .header__button-burger {
      margin: 0;
      margin-top: 8px;
    }

    .header__contacts {
      display: none;
    }

    .header__button-call {
      display: none;
    }

    .header__logo {
      margin-left: 20px;
      font-size: 20px;
      margin-right: 0;
    }
  }

  @media (max-width: 340px) {
    .header__logo {
      margin-left: 10px;
    }

    .header__right-panel {
      margin-left: 5px;
    }
  }
}

//------------------------------------------- HEADER TYPE-2

.header--type-2 {
  .header__top-panel {
    display: block;
    height: 44px;

    background-color: $color-super-light-grey;

    .header__social-links {
      display: flex;
      justify-content: space-between;
      margin-left: auto;
      width: 143px;
    }
  }

  .header__top-panel-wrapper {
    display: flex;
    align-items: center;
    height: 100%;
  }

  .header__main-panel-wrapper {
    height: 80px;

    .header__social-links {
      display: none;
    }
  }

  .header__right-panel {
    margin-left: 20px;

    .header__city,
    .header__button-search,
    .header__language {
      display: none;
    }
  }

  .header__address-full {
    font-size: 13px;
    line-height: 0;
    letter-spacing: 0.6px;
    white-space: nowrap;
  }

  .header__button-call {
    margin-right: 0;
  }

  .header__button-search {
    margin-right: 0;
  }

  .header__button-burger {
    margin-top: 28px;
    margin-bottom: auto;
  }

  .header__language {
    margin-right: 40px;
  }

  .header__city {
    margin-right: 36px;
    margin-left: 20px;
  }

  .header__items {
    margin-right: 0;
  }

  .header__item {
    padding: 28px 0;
  }

  .header__item:not(.header__item--dots)::after {
    bottom: -16px;
  }

  .header__item-link {
    padding: 24px 0;
  }

  @media (max-width: 1309px) {
    .header__items {
      margin-right: auto;
    }

    .header__button-dots {
      @include reset-button;

      position: relative;

      display: inline-block;
      width: 30px;
      height: 30px;
      margin-right: 30px;

      background-color: transparent;

      &:hover,
      &:focus {
        opacity: 0.7;
        outline: 0;
      }

      &::before {
        content: "";
        position: absolute;
        top: 12px;
        left: 12px;

        width: 4px;
        height: 4px;

        background-color: $color-dark-grey;
        border-radius: 100px;
        box-shadow: -8px 0, 8px 0;
      }
    }

    .header__item--dots {
      display: block;

      .menu__item--with-inner:last-child,
      .menu__item--with-inner:nth-last-child(2) {
        display: block;
      }
    }

    .header__item:nth-last-child(2),
    .header__item:nth-last-child(3) {
      display: none;
    }
  }

  @media (max-width: 1162px) {
    .header__item--dots {
      .menu__item--with-inner:nth-last-child(3),
      .menu__item--with-inner:nth-last-child(4) {
        display: block;
      }
    }

    .header__item:nth-last-child(4),
    .header__item:nth-last-child(5) {
      display: none;
    }

    .header__address-full {
      display: none;
    }
  }

  @media (max-width: 946px) {
    .header__item--dots {
      .menu__item--with-inner:nth-last-child(5),
      .menu__item--with-inner:nth-last-child(6) {
        display: block;
      }
    }

    .header__item:first-child,
    .header__item:nth-child(2) {
      display: none;
    }
  }

  @media (max-width: $mobile-width) {
    .header__item--dots {
      display: none;
    }

    .header__button-dots {
      display: none;
    }
  }

  @media (max-width: 700px) {
    .header__right-panel {
      margin-left: 0;
    }
  }

  @media (max-width: 600px) {
    .header__main-panel-wrapper {
      height: 60px;
    }

    .header__top-panel {
      display: none;
    }

    .header__contacts {
      display: none;
    }

    .header__button-call {
      display: none;
    }

    .header__right-panel .header__button-search {
      display: block;
      margin-right: 20px;
    }

    .header__right-panel .header__language {
      display: block;
      margin-right: 0;
    }

    .header__logo {
      margin-left: 20px;
      font-size: 20px;
      margin-right: 0;
    }
  }
}

//------------------------------------------- HEADER TYPE-3

.header--type-3 {
  .header__main-panel {
    .header__button-burger {
      margin-top: 25px;
      margin-bottom: auto;
    }

    .header__social-links {
      display: flex;
      justify-content: space-between;
      margin-left: auto;
      margin-right: 38px;
      width: 143px;
    }

    .header__items {
      display: none;
    }

    .header__address {
      display: flex;
      margin-right: 20px;

      .header__city {
        margin: auto;
        margin-left: 15px;
        margin-right: 35px;
      }

      .header__list-street {
        position: relative;

        padding-left: 0;

        font-size: 13px;
        letter-spacing: 0.6px;
        white-space: nowrap;
        list-style: none;

        svg {
          position: absolute;
          top: 7px;
          right: 0;

          fill: $color-dark-grey;
        }
      }
    }

    .header__contacts .header__city {
      display: none;
    }

    .header__content .header__social-links {
      display: flex;
      margin-right: auto;
      margin-left: 0;
    }
  }

  .header__main-panel-wrapper {
    height: 73px;
  }

  .header__bottom-panel {
    display: flex;
    background-color: $color-super-light-grey;

    .header__items {
      max-width: 1170px;
      margin: 0 auto;
    }

    .header__item {
      padding-top: 10px;
      padding-bottom: 10px;

      text-align: center;
    }

    .header__item:not(.header__item--dots)::after {
      bottom: -11px;
    }

    .header__item-link {
      padding: 16px 0;

      &:hover,
      &:focus {
        border-bottom: 0;
        opacity: 0.7;
      }
    }
  }

  @media (max-width: 1290px) {
    .header__main-panel .header__content .header__social-links {
      display: none;
    }
  }

  @media (max-width: 1110px) {
    .header__list-street {
      display: none;
    }

    .header__main-panel .header__address {
      margin-right: auto;
    }
  }

  @media (max-width: 923px) {
    .header__city {
      display: none;
    }
  }

  @media (max-width: 742px) {
    .header__main-panel-wrapper {
      height: 60px;
    }

    .header__logo {
      margin-left: 20px;
      margin-right: 0;

      font-size: 20px;
    }

    .header__number {
      display: none;
    }

    .header__button-call {
      display: none;
    }

    .header__bottom-panel {
      display: none;
    }

    .header__contacts {
      display: none;
    }
  }
}

//------------------------------------------- HEADER TYPE-4

.header--type-4 {
  .header__main-panel {
    .header__button-burger {
      margin-top: 26px;
      margin-bottom: auto;
    }

    .header__logo {
      order: 2;
      margin: 0;
    }

    .header__items {
      display: none;
    }

    .header__right-panel {
      order: 3;
    }

    .header__address {
      display: flex;
      margin-right: 0;
      margin-left: 25px;
      order: 1;

      .header__city {
        margin: auto;
        margin-left: 15px;
        margin-right: 35px;
      }

      .header__list-street {
        position: relative;

        padding-left: 0;

        font-size: 13px;
        letter-spacing: 0.6px;
        white-space: nowrap;
        list-style: none;

        svg {
          position: absolute;
          top: 7px;
          right: 0;

          fill: $color-dark-grey;
        }
      }
    }

    .header__contacts {
      display: none;
    }
  }

  .header__main-panel-wrapper {
    height: 73px;
  }

  .header__bottom-panel {
    display: flex;
    background-color: $color-super-light-grey;

    .header__items {
      max-width: 1170px;
      margin: 0 auto;
    }

    .header__item {
      padding-top: 10px;
      padding-bottom: 10px;

      text-align: center;
    }

    .header__item:not(.header__item--dots)::after {
      bottom: -11px;
    }

    .header__item-link {
      padding: 16px 0;

      &:hover,
      &:focus {
        border-bottom: 0;
        opacity: 0.7;
      }
    }
  }

  @media (max-width: 1290px) {
    .header__main-panel .header__address .header__list-street {
      display: none;
    }
  }

  @media (max-width: 1110px) {
    .header__number {
      display: none;
    }
  }

  @media (max-width: 923px) {
    .header__city {
      display: none;
    }
  }

  @media (max-width: 742px) {
    .header__main-panel .header__logo {
      margin-left: 20px;
      margin-right: 0;

      font-size: 20px;
    }

    .header__main-panel .header__address {
      display: none;
    }

    .header__main-panel-wrapper {
      height: 60px;
    }

    .header__button-call {
      display: none;
    }

    .header__bottom-panel {
      display: none;
    }
  }
}

//------------------------------------------- Работа модификаторов

.header--gradient {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.3), transparent);
  margin: 0 auto;

  .logo {
    background-image: url("../img/logo-white.svg");
    background-size: 178px 32px;
  }

  .header__item-link,
  .header__city-link,
  .header__number-link,
  .header__language-link,
  .header__list-street-link,
  .header__address-full {
    color: $color-white;
  }

  .header__button-burger svg,
  .header__city svg,
  .header__number svg,
  .header__button-search svg,
  .header__language svg,
  .header__social-link svg {
    fill: $color-white;
  }

  .header__city-item svg,
  .header__number-item svg,
  .header__language-item svg, {
    fill: $color-black;
  }

  .header__main-panel .header__address .header__list-street svg,
  .header__main-panel .header__address .header__list-street svg {
    fill: $color-white;
  }

  .header__button-dots::before {
    box-shadow: -8px 0 $color-white, 8px 0 $color-white;
    background-color: $color-white;
  }

  .header__top-panel,
  .header__bottom-panel {
    background-color: transparent;
  }
}

.header--white {
  background-color: $color-white;

  .header__top-panel,
  .header__bottom-panel {
    background-color: $color-white;
  }
}

.header--button-blue {
  .header__button-call {
    background-color: $color-blue;
    color: $color-white;

    &:hover,
    &:focus {
      background-color: $color-white;
      color: $color-blue;
      outline: 0;
    }
  }
}

.header--short {
  .header__number-inner {
    padding-left: 0;
  }
}
