.search-modal {
  padding: 23px 0;

  background-color: $color-super-light-grey;

  &__inner {
    position: relative;
    max-width: 1920px;
    margin: 0 auto;
    display: flex;
  }

  &__search-form {
    margin: 0 auto;

    input {
      background-color: $color-white;
    }
  }

  @media (max-width: $breakpoint-desktop) {
    &__inner {
      padding: 0 20px;
      padding-right: 150px;
    }
  }

  @include tablet {
    &__control {
      right: 20px;
    }
  }

  @include mobile {
    &__inner {
      padding-right: 15%;
    }

    &__control {
      right: 10px;
      width: 24px;
      height: 24px;
      margin-top: -12px;
    }
  }
}
