.modal-form {
  display: none;
  position: fixed;
  z-index: 55;
  height: 100%;
  right: 0;
  overflow-x: hidden;

  &--show {
    display: block;
  }
}

.modal-form__block {
  position: relative;
  z-index: 60;
  right: 0;

  width: 812px;
  padding: 50px 138px 80px;
  margin: auto;
  margin-top: 49px;
  margin-bottom: 50px;

  background-color: $color-ultra-light-grey;
}

.modal-form__cross {
  @include reset-button;

  position: absolute;
  top: 28px;
  right: 28px;

  width: 16px;
  height: 17px;

  outline: none;

  &:hover,
  &:focus {
    opacity: 0.7;
  }

  &::before,
  &::after {
    content: "";
    position: absolute;
    top: 40%;
    left: -2px;

    display: block;
    width: 20px;
    height: 2px;

    background-color: #2c2e30;
  }

  &::before {
    transform: rotate(-45deg);
  }

  &::after {
    transform: rotate(45deg);
  }
}

.modal-form__title {
  margin-top: 30px;
  margin-right: 28px;
  margin-bottom: 66px;

  font-size: 27px;
  font-weight: 700;
  text-align: center;
  line-height: 0;
  color: $color-dark-grey;
}

.modal-form__sub-title {
  font-size: 16px;
  font-weight: 700;
  text-align: left;
  color: $color-dark-grey;
  letter-spacing: -1px
}

.modal-form__product-card {
  display: flex;
  width: 100%;
  margin-bottom: 45px;

  background-color: $color-white;
  border: 1px solid #e2e2e2;
}

.modal-form__element {
  position: relative;

  margin-bottom: 30px;

  &--comment {
    margin-bottom: 37px;
  }
}

.modal-form__label-name {
  position: absolute;
  left: 14%;
  top: 11%;

  letter-spacing: -0.6px;
}

.modal-form__label-tel {
  position: absolute;
  top: -18px;
  left: 0;

  font-size: 12px;
  color: #a2a2a2;
}

.modal-form__label-email {
  position: absolute;
  left: 9%;
  top: 11%;

  letter-spacing: -0.6px;
}

.modal-form__image {
  width: 102px;
  height: 102px;
  margin-top: 14px;
  margin-left: 11px;
  margin-right: 8px;
}

.modal-form__product-info {
  padding-top: 7px;
  padding-left: 15px;
  padding-right: 50px;
  padding-bottom: 19px;
  max-width: 400px;

  border-left: 1px solid #e2e2e2;
}

.modal-form__product-name {
  font-size: 16px;
  font-weight: 700;
  line-height: 21px;
  letter-spacing: -0.6px;
  color: $color-dark-grey;
}

.modal-form__product-code {
  margin-bottom: 20px;
  display: flex;
  width: 100%;

  font-size: 14px;
  line-height: 31px;
  white-space: nowrap;
  letter-spacing: -0.6px;
}

.modal-form__dots {
  max-width: 62%;
  overflow: hidden;
}

.modal-form__bottom-block {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modal-form__price-text {
  display: block;

  font-size: 14px;
  color: $color-dark-grey;
  letter-spacing: -0.6px;
}

.modal-form__price {
  font-size: 22px;
  font-weight: 700;
  line-height: 30px;
}

.modal-form__input {
  width: 100%;

  outline: none;
}

.modal-form__quantity-button {
  @include reset-button;

  position: relative;

  width: 50px;
  height: 50px;
  margin-bottom: 2px;

  border: 1px solid #d6d6d6;
  border-radius: 4px;

  outline: none;

  &::before {
    content: "";
    position: absolute;
    top: 24px;
    left: 17px;

    display: block;
    width: 15px;
    height: 2px;

    background-color: $color-blue;
  }

  &--plus::after {
    content: "";
    position: absolute;
    top: 24px;
    left: 17px;

    display: block;
    width: 15px;
    height: 2px;

    background-color: $color-blue;
    transform: rotate(90deg);
  }

  &:hover,
  &:focus {
    opacity: 0.7;
  }
}

.modal-form__quantity-input {
  width: 50px;
  height: 50px;

  text-align: center;

  border: 1px solid $color-light-grey;
  outline: none;
  border-radius: 4px;
  appearance: none;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  -moz-appearance: textfield;
}

.modal-form__input {
  padding-top: 10px;
  padding-bottom: 9px;
  height: 38px;
  line-height: 30px;

  letter-spacing: -0.6px;

  background-color: inherit;
  border: 0;
  border-bottom: 1px solid $color-grey;
  border-radius: 0;
  box-shadow: none;

  &::placeholder {
    color: $color-dark-grey;
    padding-left: 0;
  }

  input::-webkit-input-placeholder, ::-webkit-input-placeholder,
  textarea::-webkit-input-placeholder
  {
    margin-left: 0;
    padding-left: 0;
  }

  &::-moz-placeholder {
    opacity: 1;
  }

  &--border-red {
    border-bottom: 1px solid $color-red;
  }
}

.modal-form__capcha {
  width: 302px;
  height: 76px;
  margin-bottom: 16px;

  background-color: $color-white;
  border: 1px solid $color-black;
  border-radius: 4px;
}

.modal-form__notification {
  margin-bottom: 40px;

  font-size: 13px;
  line-height: 17px;
  letter-spacing: -0.4px;
  color: $color-dark-grey;
}

.modal-form__consent {
  position: relative;

  margin-bottom: 32px;
}

.modal-form__consent-text {
  padding-left: 34px;
  padding-bottom: 10px;

  font-size: 13px;
  line-height: 17px;
  letter-spacing: -0.4px;
  color: $color-dark-grey;

  border-bottom: 1px dotted;
  border-color: $color-grey;

  &--padding {
    padding-bottom: 40px;
  }
}

.modal-form__red-star {
  color: $color-red;

  &--input {
    position: absolute;
    top: 5px;
    right: -7px;

    font-size: 13px;
  }
}

.modal-form__input-checkbox {
  position: absolute;

  width: 100%;
  height: 100%;

  opacity: 0;
  cursor: pointer;
}

.modal-form__input-checkbox + label::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;

  display: block;
  width: 25px;
  height: 25px;

  border-radius: 5px;
  border: 1px solid $color-grey;
  background-color: $color-white;
  cursor: pointer;
}

.modal-form__input-checkbox:checked + label::after {
  content: "";
  position: absolute;
  top: 5px;
  left: 4px;

  display: block;
  width: 25px;
  height: 25px;

  background-color: transparent;
  background-image: url("../img/icon-check.svg");
  background-repeat: no-repeat;
  cursor: pointer;
}

.modal-form__button-order {
  @include reset-button;

  display: block;
  padding: 9px 40px 9px;
  margin: 0 auto;
  height: 53px;

  color: $color-white;
  letter-spacing: -0.6px;

  background-color: $color-blue;
  border-radius: 4px;

  &:hover,
  &:focus {
    opacity: 0.7;
  }
}

.modal-form__error-message {
  position: absolute;
  left: 0;
  top: 31px;

  font-size: 12px;
  color: $color-red;
  white-space: nowrap;
}

.modal-form__consent .modal-form__error-message {
  top: auto;
  bottom: 7px;
  right: -10px;
}

.modal-form__rating-area {
  overflow: hidden;
  width: 110px;
}

.modal-form__rating-area:not(:checked) > input {
  display: none;
}

.modal-form__rating-area:not(:checked) > label {
  float: right;
  width: 22px;
  padding: 0;
  cursor: pointer;
  font-size: 22px;
  line-height: 50px;
  color: lightgrey;
  text-shadow: 1px 1px #bbb;
}

.modal-form__rating-area:not(:checked) > label:before {
  content: '★';
}

.modal-form__rating-area > input:checked ~ label {
  color: gold;
  text-shadow: 1px 1px #c60;
}

.modal-form__rating-area:not(:checked) > label:hover,
.modal-form__rating-area:not(:checked) > label:hover ~ label {
  color: gold;
}

.modal-form__rating-area > input:checked + label:hover,
.modal-form__rating-area > input:checked + label:hover ~ label,
.modal-form__rating-area > input:checked ~ label:hover,
.modal-form__rating-area > input:checked ~ label:hover ~ label,
.modal-form__rating-area > label:hover ~ input:checked ~ label {
  color: gold;
  text-shadow: 1px 1px goldenrod;
}

.modal-form__rating-area > label:active {
  position: relative;
}

.modal-form__upload-wrapper {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 28px;
}

.modal-form__upload input[type="file"] {
  display: none;
}

.modal-form__upload {
  position: relative;
  overflow: hidden;
  width: 175px;
  height: 43px;
  margin-right: 12px;

  border: 1px solid $color-blue;
  border-radius: 4px;
  color: #fff;
  text-align: center;
}

.modal-form__upload:hover,
.modal-form__upload:focus {
  opacity: 0.7;
}

.modal-form__upload label {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.modal-form__upload span {
  margin-right: 6px;

  font-size: 14px;
  line-height: 40px;
  letter-spacing: -0.5px;
  color: $color-blue;
}

.modal-form__upload-file-name {
  margin-top: 16px;

  font-size: 12px;
  line-height: 16px;
  letter-spacing: -.5px;
  white-space: nowrap;
}

//-------------------------REVIEW


.modal-form--review {
  .modal-form__title {
    margin-bottom: 68px;
  }

  .modal-form__element {
    margin-bottom: 34px;
  }

  .modal-form__sub-title {
    margin-bottom: -17px;
  }

  .modal-form__rating-area {
    margin-bottom: 22px;
  }

  .modal-form__sub-title--second {
    margin-bottom: 26px;
  }

  .modal-form__element {
    margin-bottom: 30px;

    &--name {
      margin-bottom: 35px;
    }

    &--comments {
      margin-bottom: 35px;
    }
  }

  .modal-form__label-name {
    left: 7%;
  }
}

@media (max-width: $tablet-max-width) {
  .modal-form__block {
    width: 700px;
    padding: 30px 50px 30px;
  }

  .modal-form__title {
    margin-bottom: 40px;
  }

  .modal-form__product-info {
    padding: 9px 50px 20px 12px;
    max-width: 475px;
  }
}

@media (max-width: $mobile-width) {
  .modal-form__block {
    width: 100%;
    padding: 30px 50px 30px;
  }

  .modal-form__product-info {
    padding: 9px 12px 20px 12px;
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;

    border-left: none;
  }

  .modal-form__label-name {
    visibility: hidden;
  }

  .modal-form__label-email {
    visibility: hidden;
  }

  .modal-form__title {
    font-size: 20px;
  }

  .modal-form__product-card {
    flex-direction: column;
  }

  .modal-form__image {
    margin-left: auto;
    margin-right: auto;
  }

  .modal-form__product-name {
    font-size: 14px;
  }

  .modal-form__price {
    font-size: 18px;
  }

  .modal-form__product-code-number {
    margin: 0;
  }

  .modal-form__price-text {
    margin-right: auto;
    margin-bottom: 20px;
  }

  .modal-form__quantity-wrapper {
    margin-right: auto;
  }

  .modal-form__capcha {
    max-width: 302px;
    width: auto;
  }

  .modal-form__dots {
    max-width: 70px;
  }

  .modal-form__bottom-block {
    flex-direction: column;
    width: 100%;
  }
}
