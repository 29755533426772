.contacts {
  width: 325px;
  font-style: normal;

  &__list {
    @include reset-list;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    &--inner {
      display: none;
    }
  }

  &__item {
    position: relative;
    margin-bottom: 16px;
    padding-top: 5px;
    padding-left: 37px;
    font-size: 13px;
    line-height: 18px;
    color: $color-grey;

    a {
      position: relative;
      color: inherit;
    }

    &:last-child {
      margin-bottom: 0;
    }

    &::before {
      @include pseudo;
      top: 0;
      left: 0;
      width: 24px;
      height: 24px;
      background-repeat: no-repeat;
      background-position: center center;
    }

    &--phone::before {
      background-image: url("../img/footer/sharp-phone-24px.svg");
    }

    &--mail::before {
      background-image: url("../img/footer/sharp-email-24px.svg");
    }

    &--address {
      line-height: 25px;

      &::before {
        background-image: url("../img/footer/sharp-location_on-24px.svg");
      }
    }

    &--inner {
      padding: 0;
      margin: 0;
      margin-bottom: 6px;

      a {
        margin: 0;
      }
    }

    svg {
      display: none;
    }

    &--with-inner {
      svg {
        display: block;
        position: absolute;
        top: 13px;
        right: -20px;
        margin-top: -6px;
      }
    }
  }

  &--menu-burger {
    .contacts__item {
      padding: 0;
      padding-left: 35px;

      display: flex;
      flex-direction: column;
      align-items: flex-start;

      line-height: 25px;

      &--inner {
        padding: 0;
      }

      a:not(:first-of-type) {
        display: none;
      }
    }

    .contacts__item--address,
    .contacts__item--phone {
      margin-bottom: 9px;
    }

    .contacts__item--mail {
      margin-bottom: 0;
    }
  }

  &--footer {
    .contacts__item {
      margin-bottom: 10px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    .contacts__item {
      a {
        padding-right: 0;
      }
    }

    .contacts__item--phone,
    .contacts__item--mail {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
  }
}

@include tablet {
  .contacts--footer {
    .contacts__item {
      margin-bottom: 9px;
      padding-top: 0;
      padding-right: 0;

      font-size: 14px;
      line-height: 19px;

      a {
        padding-right: 0;
        line-height: 25px;
      }
    }
  }
}

@include mobile {
  .contacts {
    width: auto;
  }

  .contacts--footer {
    .contacts__item {
      font-size: 13px;
      line-height: 18px;
    }
  }
}

// @media (min-width: $tablet-width) {
  .contacts--menu-burger {
    .contacts__item:hover,
    .contacts__item:focus {
      a {
        display: block;
      }

      svg {
        fill: $color-blue;
        transform: rotate(180deg);
      }
    }
  }

  .contacts__item a:hover,
  .contacts__item a:focus {
    span {
      color: $color-blue;
    }
    color: $color-blue;
    text-decoration: underline;
  }


  .contacts__item:active {
    a[href] {
      opacity: 0.7;
    }
  }
// }
