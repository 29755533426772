//  ======== Карточка проекта =============================================

.card-project {
  position: relative;
  width: 100%;

  img {
    width: 100%;
    height: 266px;
    display: block;
    object-fit: cover;
  }

  &__desc {
    min-height: 236px;
    padding: 33px 20px;
    background-color: $color-ultra-light-grey;
    border: 1px solid $color-super-light-grey;

    &::after {
      @include pseudo;
      bottom: 12px;
      right: 17px;
      width: 24px;
      height: 24px;
      background-repeat: no-repeat;
      background-position: center center;
      background-image: url("../img/sharp-arrow_back-24px.svg");
    }
  }

  span {
    display: block;
    margin-bottom: 10px;

    font-size: 14px;
    line-height: 19px;
    letter-spacing: 1px;
    color: $color-light-grey;
    text-transform: uppercase;
  }

  p {
    margin: 0;
    padding: 0;

    // @include cut-text(4);

    font-size: 18px;
    line-height: 31px;
    letter-spacing: 0.73px;
    color: $color-dark-grey;
  }
}

//  ======== Карточка проекта - большая =============================================

.card-project--big {
  width: 100%;

  img {
    width: 812px;
    height: 502px;
  }

  .card-project__desc {
    position: absolute;
    width: 50%;
    bottom: 0;
    right: 0;
    padding: 35px 30px;
    background-color: $color-ultra-light-grey;
  }

  @include mobile {
    img {
      width: 100%;
      height: 266px;
    }

    .card-project__desc {
      position: static;
      width: 100%;
      padding: 33px 20px;
    }
  }
}

// @media (min-width: $tablet-width) {
  .card-project:hover,
  .card-project:focus {
    .card-project__desc {
      background-color: $color-card-service-hover;
    }
  }

  .card-project:active {
    opacity: 0.7;
  }
// }
