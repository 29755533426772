.production-main {
  padding-top: 122px;
  padding-bottom: 68px;
}

.production-main__section-header .section-header__button {
  width: 162px;
}

.production-main__list {
  @include reset-list;

  display: flex;
  flex-wrap: wrap;
}

.production-main__item {
  width: 24.2%;
  margin-right: 1%;
  margin-bottom: 0.9%;

  &:nth-child(4),
  &:last-child {
    margin-right: 0;
  }
}

@media (max-width: $desktop-width) {
  .production-main {
    padding-top: 50px;
    padding-bottom: 50px
  }

  .production-main__item {
    width: 32.5%;
    margin-right: 1%;
    margin-bottom: 0.9%;

    &:nth-child(3),
    &:nth-child(6) {
      margin-right: 0;
    }

    &:nth-child(4) {
      margin-right: 1%;
    }
  }
}

@media (max-width: $tablet-width) {
  .production-main__item {
    width: 100%;
    margin-right: 0;
    margin-bottom: 0.9%;
  }
}
