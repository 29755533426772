.projects-main {
  padding-top: 72px;
  padding-bottom: 67px;
}

.projects-main__list {
  @include reset-list;

  display: flex;
  flex-wrap: wrap;
}

.projects-main__item {
  width: 24.5%;
  margin-right: 0.75%;
  margin-bottom: 1.3%;
}

.projects-main__item:first-child {
  width: 49.5%;
  margin-right: 0.75%;
}

.projects-main__item:last-child {
  margin-right: 0;
}

@media (max-width: $desktop-width) {
  .projects-main {
    padding-top: 50px;
    padding-bottom: 30px;
  }

  .projects-main__item {
    width: 49.5%;
    margin-right: 1%;

    img {
      width: 100%
    }
  }

  .projects-main__item:first-child {
    width: 100%;
    margin-right: 0;
  }
}

@media (max-width: $tablet-width) {
  .projects-main__item {
    width: 100%;
    margin-right: 0;
  }
}
