.projects {
  &__tab {
    margin-bottom: 16px;
  }

  &__list {
    @include reset-list;

    display: flex;
    flex-wrap: wrap;

    .projects__item {
      margin-bottom: 32px;
      margin-right: 0.91%;

      &:nth-child(4n + 3) {
        margin-right: 0;
      }
    }
  }

  &__item {
    width: 24.26%;

    &--big {
      width: 49.5%;
    }
  }
}

@media (max-width: 1200px) {
  .projects {
    &__list {
      .projects__item,
      .projects__item:nth-child(4n + 3) {
        margin-right: 1.7%;

        &:nth-child(3n + 2) {
          margin-right: 0;
        }
      }
    }

    &__item {
      width: calc(100% / 3 - 1.7%);

      &--big {
        width: 65%;
      }
    }
  }
}

@media (max-width: $tablet-max-width) {
  .projects {
    &__list {
      .projects__item,
      .projects__item:nth-child(4n + 3) {
        margin-right: 0;

        &:nth-child(2n) {
          margin-right: 2%;
        }
      }
    }

    &__item {
      width: 49%;

      &--big {
        width: 100%;
      }

      img {
        width: 100%;
      }
    }
  }
}

@media (max-width: $mobile-width) {
  .projects {
    &__list {
      .projects__item {
        margin-bottom: 16px;
      }

      .projects__item:nth-child(2n) {
        margin-right: 0;
      }
    }

    &__item {
      width: 100%;

      img {
        height: 266px;
      }

      .card-project--big {
        .card-project__desc {
          position: static;
          width: 100%;
          padding: 33px 20px;
        }
      }
    }
  }
}
