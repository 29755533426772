.contacts-main {
  position: relative;

  display: flex;
  padding-bottom: 142px;

  border-top: 1px solid #e6e6e6;
}

.contacts-main__wrapper {
  padding-top: 18px;
}

.contacts-main__contacts {
  width: 50%;
  padding-top: 62px;
}

.contacts-main__page-title {
  margin-bottom: 24px;
}

.contacts-main__info {
  display: flex;
  flex-wrap: wrap;
}

.contacts-main__address,
.contacts-main__tel,
.contacts-main__email,
.contacts-main__social {
  width: 260px;
  margin-right: 13px;
  margin-bottom: 48px;

  p {
    margin-bottom: 4px;

    font-weight: 700;
    color: $color-dark-grey;
    letter-spacing: -0.6px;
  }
}

.contacts-main__address .contacts-main__address-text {
  margin-top: 0;
  width: 220px;

  font-weight: 400;
  color: $color-light-grey;
}

.contacts-main__tel-wrap {
  display: flex;
  flex-wrap: wrap;
}

.contacts-main__tel-item a {
  color: $color-light-grey;

  &:hover,
  &:focus {
    opacity: 0.7;
  }
}

.contacts-main__email a {
  text-decoration: underline;
  color: $color-blue;

  &:hover,
  &:focus {
    opacity: 0.7;
  }
}

.contacts-main__map {
  position: absolute;
  left: 50%;

  width: 50%;
  height: 100%;

  iframe {
    width: 100%;
    height: 100%;
    display: block;

    overflow: hidden;
  }
}

@media (max-width: $desktop-width) {
  .contacts-main__contacts {
    padding-top: 25px;
  }
}

@media (max-width: $tablet-width) {
  .contacts-main {
    flex-direction: column;
    padding-bottom: 0;
  }

  .contacts-main__map {
    position: static;
    width: 100%;

    iframe {
      height: 300px;
    }
  }

  .contacts-main__contacts{
    width: 100%;
  }
}
