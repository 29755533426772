.page-footer {
  position: relative;
  padding-top: 45px;

  border-top: 1px solid $color-footer-border;
  border-bottom: 1px solid $color-footer-border;

  &__top,
  &__bottom {
    padding: 0 20px;
  }

  &__top {
    padding-bottom: 40px;
    display: flex;
  }

  &__wrap {
    display: flex;
    flex-direction: row;

    &--first {
      width: 24%;
      min-width: 350px;
      margin-right: calc(18% - 135px);
      flex-direction: column;
    }

    &--second {
      width: 24%;
      margin-right: calc(18% - 135px);
      justify-content: space-between;
    }

    &--third {
      width: 29%;
      margin-right: auto;
      justify-content: space-between;
    }
  }

  &__subscribe {
    margin-bottom: 40px;
  }

  &__contacts {
    width: 90%;
    margin-bottom: 17px;
  }

  &__menu {
    min-width: 120px;

    &--about {
      margin-right: 20px;
    }

    &--services {
      margin-right: 20px;
    }
  }

  &__bottom {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  &__anchor-link {
    position: absolute;
    right: 4%;
    bottom: 37px;

    width: 62px;
    height: 62px;

    border: 1px solid $color-light-grey;
    border-radius: 5px;
    background-color: $color-white;
    background-repeat: no-repeat;
    background-position: center center;
    background-image: url("../img/footer/sharp-keyboard_arrow_up-24px.svg");
  }
}

//  ==================== Темная тема футера =====================================
.page-footer--dark {
  color: $color-white;
  background-color: $color-dark-grey;

  .page-footer__top {
    border-top-color: transparent;
  }

  .social__title,
  .menu__title,
  .copyright__developer b,
  span,
  label,
  a {
    color: $color-white;
  }

  svg {
    fill: $color-white;
  }

  .copyright__rights span,
  .copyright__links a {
    color: $color-footer-dark-copyright;
  }
}

@media (max-width: $breakpoint-desktop) {
  .page-footer {
    padding: 0 50px;
    border: none;

    &__bottom {
      .page-footer__wrap {
        &--first {
          min-width: 0;
        }
      }
    }

    &__top,
    &__bottom {
      padding: 0;
    }

    &__top {
      padding-top: 35px;
      flex-direction: column;
      border-top: 1px solid $color-footer-border;
    }

    &__wrap {
      margin: 0;
      width: 100%;

      &--first {
        padding-bottom: 22px;
        border-bottom: 1px solid $color-footer-border;
      }

      &--second,
      &--third {
        padding-top: 30px;
        padding-bottom: 20px;
        justify-content: flex-start;
      }

      &--third {
        padding-bottom: 30px;
      }
    }

    &__container {
      display: flex;
    }

    &__subscribe {
      margin-bottom: 47px;
    }

    &__contacts,
    &__menu--about,
    &__menu--services {
      margin: 0;
      margin-right: 14%;
    }

    &__contacts,
    &__social,
    &__menu--about,
    &__menu--production,
    &__menu--services {
      width: 37%;
    }

    &__bottom {
      padding-top: 30px;
      padding-bottom: 40px;

      display: flex;
      flex-wrap: wrap;

      border-top: 1px solid $color-footer-border;

      .page-footer__wrap {
        width: 50%;
        padding: 0;
        flex-shrink: 0;

        &--first {
          min-width: 0;

          order: 1;
          margin-top: 16px;

          border: none;
        }

        &--second {
          width: 100%;
          flex-direction: column;

          a {
            margin: 0;

            &:last-child {
              margin-bottom: 16px;
            }
          }
        }

        &--third {
          order: 2;
          position: absolute;
          bottom: 35px;
          left: 50.5%;
          width: 40%;
        }
      }
    }

    &__anchor-link {
      right: 55px;
      bottom: 40px;
    }
  }
}

// @media (max-width: $tablet-max-width) {
//   .page-footer {
//     padding: 0 50px;
//     border: none;

//     &__top,
//     &__bottom {
//       padding: 0;
//     }

//     &__top {
//       padding-top: 35px;
//       flex-direction: column;
//       border-top: 1px solid $color-footer-border;
//     }

//     &__wrap {
//       margin: 0;
//       width: 100%;

//       &--first {
//         padding-bottom: 22px;
//         border-bottom: 1px solid $color-footer-border;
//       }

//       &--second,
//       &--third {
//         padding-top: 30px;
//         padding-bottom: 20px;
//         justify-content: flex-start;
//       }

//       &--third {
//         padding-bottom: 30px;
//       }
//     }

//     &__container {
//       display: flex;
//     }

//     &__subscribe {
//       margin-bottom: 47px;
//     }

//     &__contacts,
//     &__menu--about,
//     &__menu--services {
//       margin: 0;
//       margin-right: 14%;
//     }

//     &__contacts,
//     &__social,
//     &__menu--about,
//     &__menu--production,
//     &__menu--services {
//       width: 37%;
//     }

//     &__bottom {
//       padding-top: 30px;
//       padding-bottom: 40px;

//       display: flex;
//       flex-wrap: wrap;

//       border-top: 1px solid $color-footer-border;

//       .page-footer__wrap {
//         width: 50%;
//         padding: 0;
//         flex-shrink: 0;

//         &--first {
//           min-width: 0;

//           order: 1;
//           margin-top: 16px;

//           border: none;
//         }

//         &--second {
//           width: 100%;
//           flex-direction: column;

//           a {
//             margin: 0;

//             &:last-child {
//               margin-bottom: 16px;
//             }
//           }
//         }

//         &--third {
//           order: 2;
//           position: absolute;
//           bottom: 35px;
//           left: 50.5%;
//           width: 40%;
//         }
//       }
//     }

//     &__anchor-link {
//       right: 55px;
//       bottom: 40px;
//     }
//   }
// }

@media (max-width: $mobile-width) {
  .page-footer {
    padding: 0 20px;

    &__container {
      flex-direction: column;
    }

    &__contacts {
      margin-bottom: 30px;
    }

    &__wrap {
      padding-right: 0;

      &--first {
        min-width: 0;
      }

      &--second {
        padding-bottom: 30px;
        border-bottom: 1px solid $color-footer-border;
      }

      &--third {
        flex-direction: column;
      }
    }

    &__subscribe {
      margin-bottom: 30px;
    }

    &__contacts {
      margin-bottom: 15px;
    }

    &__menu--about,
    &__menu--services {
      margin: 0;
    }

    &__contacts,
    &__social,
    &__menu--production,
    &__menu--services {
      width: 100%;
    }

    &__menu--about,
    &__menu--main {
      width: 48.5%;
    }

    &__menu--about {
      margin-right: 3%;
    }

    &__menu--services,
    &__menu--production {
      .menu__list {
        width: 70%;
      }
    }

    &__menu--services {
      padding-bottom: 30px;
      border-bottom: 1px solid $color-footer-border;
    }

    &__menu--production {
      padding-top: 30px;
    }

    &__bottom {
      padding-bottom: 35px;

      .page-footer__wrap {
        width: 100%;
        border: none;

        &--first {
          margin-bottom: 45px;
        }

        &--second {
          a:first-child {
            margin-bottom: 16px;
          }

          a:last-child {
            margin: 0;
          }
        }

        &--third {
          position: static;
        }
      }
    }

    &__anchor-link {
      right: 20px;
      bottom: 35px;
    }
  }
}

// @media (min-width: $tablet-width) {
  .page-footer__anchor-link:hover,
  .page-footer__anchor-link:focus {
    opacity: 0.7;
  }

  .page-footer__anchor-link:active {
    opacity: 1;
  }
// }
