//  ======== Карточка партнера =============================================

.card-partner {
  min-height: 526px;
  position: relative;

  border: 1px solid $color-card-partner-border;

  padding-top: 0;
  padding-bottom: 30px;
  padding-left: 27px;
  padding-right: 40px;

  display: flex;
  flex-direction: column;

  overflow: hidden;

  &__media-wrap {
    margin-top: -10px;
    align-self: center;

    img {
      display: block;
    }
  }

  &__name {
    min-height: 55px;
    margin: 0;
    margin-bottom: 15px;

    font-weight: 700;
    font-size: 20px;
    line-height: 27px;
    color: $color-dark-grey;
  }

  p {
    margin: 0;
    margin-bottom: 27px;

    font-size: 14px;
    line-height: 25px;
    letter-spacing: -0.2px;

    // @include cut-text(4);

    color: $color-light-grey;
  }

  &__contacts {
    margin-top: auto;

    font-size: 14px;
    line-height: 25px;

    & > a {
      color: $color-blue;
      text-decoration: underline;
    }
  }

  &__contacts-phones,
  &__contacts-emails {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  &__contacts-phones a,
  &__contacts-emails a {
    color: $color-dark-grey;
    text-decoration: none;
  }
}

// @media (min-width: $tablet-width) {
  .card-partner {
    &__contacts {
      & > a:hover,
      & > a:focus {
        opacity: 0.7;
      }

      & > a:active {
        opacity: 1;
      }
    }

    &__contacts-phones a:hover,
    &__contacts-emails a:hover,
    &__contacts-phones a:focus,
    &__contacts-emails a:focus {
      color: $color-blue;
      text-decoration: underline;
    }

    &__contacts-phones a:active,
    &__contacts-emails a:active {
      opacity: 0.7;
    }
  }
// }
