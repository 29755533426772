.search {
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
  color: $color-light-grey;

  &__inner {
    max-width: 1088px;
  }

  &__result {
    & > p {
      padding: 22px 0;
      display: block;

      font-weight: 400;
      color: $color-search-result-count;
      border-bottom: 1px dotted $color-grey;
    }
  }

  &__list {
    @include reset-list;
    padding-top: 35px;
    padding-bottom: 45px;
    counter-reset: num;
  }

  &__item {
    position: relative;
    margin-bottom: 41px;
    // overflow: hidden;

    &::before {
      content: counter(num) '.';
      counter-increment: num;
      color: $color-blue;
    }

    &:last-child {
      margin-bottom: 0;
    }

    & > a {
      // margin-bottom: 7px;
      // display: inline-block;

      font-size: 18px;
      color: $color-blue;
      text-decoration: underline;
    }

    .search__breadcrumbs {
      margin-top: 7px;
      margin-bottom: 15px;
      flex-wrap: wrap;
    }

    p {
      margin: 0;
      padding: 0;

      @include cut-text(3);

      &::before {
        content: "... ";
      }
    }
  }
}

// @media (min-width: $tablet-width) {
  .search__item > a:hover,
  .search__item > a:focus {
    opacity: 0.7;
  }

  .search__item > a:active {
    opacity: 1;
  }
// }
