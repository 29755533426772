.about-main {
  position: relative;

  padding-top: 113px;
  padding-bottom: 88px;

  &::before {
    content: "";
    position: absolute;
    top: 284px;
    right: 54%;
    z-index: -1;

    width: 5000px;
    height: 520px;

    background: linear-gradient(to left, $color-ultra-light-grey 80%, $color-ultra-light-grey 80%);
  }
}

.about-main__wrapper {
  display: flex;
}

.about-main__text-block {
  max-width: 532px;
  margin-left: 154px;
  margin-top: 80px;
}

.about-main__page-title {
  letter-spacing: -2px;
}

.about-main__sub-title {
  margin-top: 7px;
  margin-bottom: 40px;

  font-size: 14px;
  line-height: 19px;
  text-transform: uppercase;

  color: $color-blue;
}

.about-main__text {
  letter-spacing: -0.6px;
  margin-top: 0;

  &--first {
    margin-bottom: 30px;
  }

  &--second {
    margin-bottom: 70px;
  }
}

.about-main__button {
  padding: 11px 20px 11px 24px;

  &--detailed {
    margin-right: 14px;
  }
}

@media (max-width: 1350px) {
  .about-main::before {
    content: none;
  }

  .about-main__image {
    display: none;
  }

  .about-main__text-block {
    margin: 0 auto;
  }
}

@media (max-width: $desktop-width) {
  .about-main {
    padding-top: 50px;
    padding-bottom: 25px;
  }

  .about-main__text-block {
    max-width: none;
  }

  .about-main__text--second {
    margin-bottom: 50px;
  }
}

@media (max-width: $mobile-width) {
  .about-main__button {
    display: block;
    width: 280px;
    margin: 0 auto;
    margin-bottom: 20px;
  }

  .about-main__text--second {
    margin-bottom: 35px;
  }
}
