.project-card {
  padding-top: 99px;

  .inner-page {
    padding-bottom: 95px;
  }

  .inner-page__column--right {
    margin-top: -7px;
  }

  &__introduction {
    max-width: 1920px;
    margin: 0 auto;
    margin-bottom: 36px;
    padding-top: 40px;
    padding-bottom: 40px;

    background-repeat: no-repeat;
    background-position: 0 0;
    background-size: cover;
    background-color: #9e8993;
    background-image: url("../img/projects/steamer-4097525_1920.jpg");

    @media (min-resolution: $retina-dpi), (min-resolution: $retina-dppx) {
      background-image: url("../img/projects/steamer-4097525_1920@2x.jpg");
    }
  }

  &__container {
    width: 674px;
    height: 517px;
    padding: 30px 40px;
    background-color: $color-white;

    .breadcrumbs {
      margin-bottom: 30px;
    }

    .page-title {
      margin-bottom: 27px;
    }

    p {
      max-height: 90px;
      margin: 0;
      margin-bottom: 45px;

      font-size: 16px;
      line-height: 30px;
      color: $color-light-grey;

      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &__buttons {
    .button {
      margin-right: 10px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &__menu {
    margin-bottom: 20px;
  }

  &__desc {
    width: 100%;
  }

  .list--card .list__item {
    margin-bottom: 14px;
  }

  .list,
  &__gallery,
  &__employees {
    margin-bottom: 64px;
  }

  .list--card {
    margin-bottom: 50px;
  }

  &__done {
    margin-bottom: 62px;
  }

  &__documents.list {
    margin-bottom: 0;
  }

  &__services,
  &__partners,
  &__publications {
    .list__item {
      margin-bottom: 0;
    }
  }

  .button--menu-left {
    margin-bottom: 40px;
    display: none;
  }

  .button--active {
    color: $color-white;
    background-color: $color-blue;
  }

  @media (max-width: $breakpoint-desktop) {
    .button--menu-left {
      display: block;
    }

    .inner-page__wrap {
      margin-bottom: 0;
      flex-direction: column;
    }

    .inner-page__column--left {
      width: 100%;
      display: block;

      .buttons {
        display: none;
      }
    }

    .menu--left {
      display: none;
    }

    .menu--left-opened {
      display: block;
    }
  }

  @include mobile {
    .project-card {
      &__introduction {
        background-image: none;
      }

      &__container {
        width: 100%;
        height: auto;
        padding-left: 15px;
        padding-right: 15px;

        @media (max-width: 500px) {
          .button {
            width: 100%;
            margin: 0;
            margin-bottom: 15px;
          }
        }
      }
    }
  }
}
