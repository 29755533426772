//  ======== Карточка услуги =============================================

.card-service {
  position: relative;
  width: 100%;
  min-height: 265px;

  background-color: $color-ultra-light-grey;
  border: 1px solid $color-super-light-grey;

  a {
    height: 100%;
    padding: 40px 25px;

    display: flex;
    flex-direction: column;
    align-items: center;

    &::after {
      @include pseudo;
      bottom: 6px;
      right: 13px;
      width: 24px;
      height: 24px;
      background-repeat: no-repeat;
      background-position: center center;
      background-image: url("../img/sharp-arrow_back-24px.svg");
    }
  }

  img {
    width: 48px;
    height: 48px;
    display: block;
    margin-bottom: 24px;
  }

  &__name {
    min-height: 45px;
    margin: 0;
    margin-bottom: 13px;
    padding: 0;

    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    color: $color-dark-grey;
    text-align: center;
  }

  p {
    // width: 80%;
    margin: 0;
    // margin-top: auto;
    padding: 0 20px;

    font-size: 14px;
    line-height: 25px;
    color: $color-light-grey;
    text-align: center;
  }
}

// @media (min-width: $tablet-width) {
  .card-service:hover,
  .card-service:focus {
    background-color: $color-card-service-hover;
    border-color: $color-blue;
  }

  .card-service:active {
    opacity: 0.7;
  }
// }
