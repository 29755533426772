.card-product {
  position: relative;
  width: 100%;
  // height: 100%;
  min-height: 494px;
  // max-height: 513px;
  padding: 20px 27px;
  padding-top: 32px;

  display: flex;
  flex-direction: column;

  background-color: $color-white;
  border: 1px solid $color-product-photo-border;

  a {
    display: flex;
    flex-direction: column;
  }

  &__media-wrap {
    margin-bottom: 20px;
    width: 68%;
    max-width: 214px;
    height: 214px;

    flex-shrink: 0;
    align-self: center;

    img {
      width: 100%;
      height: 214px;

      display: block;
      object-fit: cover;
    }
  }

  &__name {
    margin: 0;
    margin-bottom: 10px;
    padding: 0;
    padding-right: 30px;

    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    color: $color-dark-grey;
    letter-spacing: -0.35px;
  }

  &__available {
    margin: 0;
    margin-top: auto;
    margin-bottom: 10px;

    font-weight: 400;
    font-size: 14px;
    line-height: 25px;
    color: $color-light-grey;
  }

  &__price {
    margin: 0;
    padding: 0;
    margin-bottom: 18px;

    font-weight: 700;
    font-size: 22px;
    line-height: 30px;
    color: $color-dark-grey;
  }

  p.card-product__price--old {
    margin: 0;
    margin-bottom: -2px;
    font-weight: 400;
    font-size: 14px;
    line-height: 25px;
    color: $color-product-old-price;
    text-decoration: line-through;
    letter-spacing: normal;
  }

  &__button {
    width: 100%;
    // margin-top: auto;
  }

  &::before {
    @include pseudo;
    top: 17px;
    left: 0;

    padding: 4px 18px;

    font-size: 14px;
    line-height: 19px;
    color: $color-white;
  }

  &--stock::before {
    content: "Акция";
    background-color: $color-product-card-stock-bg;
  }

  &--hit::before {
    content: "Хит продаж";
    background-color: $color-product-card-hit-bg;
  }

  &--popular::before {
    content: "Популярный";
    background-color: $color-product-card-popular-bg;
  }

  &--new::before {
    content: "Новинка";
    background-color: $color-product-card-new-bg;
  }

  &--discount::before {
    content: "Скидка";
    background-color: $color-product-card-discount-bg;
  }

  // @media (min-width: $tablet-width) {
    & a:hover,
    & a:focus {
      .card-product__name {
        color: $color-blue;
      }
    }

    & a:active {
      opacity: 0.7;
    }
  }
// }
