.catalog {
  &__filter {
    margin-bottom: 37px;
  }

  &__menu {
    margin-bottom: 16px;
  }

  &__wrap {
    margin-bottom: 28px;

    display: flex;
    justify-content: space-between;
  }

  &__style {
    display: flex;
  }

  &__products {
    margin-bottom: 17px;

    .list__item {
      margin-bottom: 32px;
    }
  }

  &__style-toggle {
    @include reset-button;

    margin-top: 9px;

    width: 50px;
    height: 50px;

    display: flex;

    background-color: $color-white;
    border: 1px solid $color-pagination-border;
    border-radius: 4px;

    svg {
      margin: auto;
      fill: $color-light-grey
    }

    &--card {
      margin-right: 8px;
    }

    &--current {
      background-color: $color-blue;
      border-color: $color-blue;

      svg {
        fill: $color-white;
      }
    }
  }

  &__button-filter {
    display: none;
  }

  &__filter-container {
    border-bottom: 1px solid #d2d2d2;
  }

  &__buttons {
    width: 100%;

    margin: 0 auto;
    padding-top: 13px;
    padding-bottom: 13px;

    // display: flex;
    justify-content: space-between;
    display: none;

    .button {
      width: 49%;
    }

    .button--active {
      color: $color-blue;
      background-color: $color-white;
    }
  }

  @media (max-width: 1544px) {
    .list--card .list__item {
      width: 49%;
    }

    .list--card .list__items {
      .list__item {
        margin-right: 2%;
        margin-bottom: 2%;

        &:nth-child(3n) {
          margin-right: 2%;
        }

        &:nth-child(2n) {
          margin-right: 0;
        }
      }
    }
  }

  @media (max-width: 1187px) {
    &__filter-container {
      padding-top: 100px;
    }

    .inner-page {
      padding-top: 20px;
    }

    .inner-page__column--left {
      display: none;
    }

    &__buttons {
      display: flex;
    }
  }

  @include mobile {
    &__wrap {
      flex-direction: column;
    }

    .list--card .list__item {
      width: 100%;
    }

    .list--card .list__items {
      .list__item {
        margin-right: 0;

        &:nth-child(3n) {
          margin-right: 0;
        }
      }
    }
  }
}
