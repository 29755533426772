.services-main {
  padding-top: 184px;
  padding-bottom: 55px;
  margin-top: -99px;
}

.services-main__services {
  padding: 0;
}

@media (max-width: $desktop-width) {
  .services-main {
    padding-top: 155px;
  }
}

@include mobile {
  .services-main {
    padding-top: 150px;
  }
}
