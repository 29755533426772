.contact {
  padding-top: 35px;
  padding-bottom: 35px;
}

.contact__wrapper {
  max-width: 1640px;

  padding: 48px 50px 66px;

  background-image: url("../img/main-page/background-call.jpg");
  background-size: cover;

  @media (min-resolution: $retina-dpi),
  (min-resolution: $retina-dppx) {
    background-image: url("../img/main-page/background-call@2x.jpg");
  }
}

.contact__question {
  margin-bottom: 23px;

  font-size: 36px;
  font-weight: 700;
  line-height: 48px;
  color: $color-dark-grey;
  letter-spacing: -1.6px;
}

.contact__text {
  margin-bottom: 41px;
  max-width: 674px;

  color: #6a6a6a;
  letter-spacing: -0.6px;
}

.contact__buttons-wrapper {
  display: flex;
}

.contact__button {

  &--call {
    width: 156px;
    padding: 13px 10px;
    margin-right: 16px;
  }

  &--texting {
    padding: 12px 16px 10px 16px;
    width: 219px;
    background-color: transparent;
  }
}

@media (max-width: $desktop-width) {
  .contact {
    padding: 0;
  }

  .contact__wrapper {
    padding-top: 0;
    padding-bottom: 0;

    background-image: none;
    background-color: transparent;
  }
}

@media (max-width: $tablet-width) {
  .contact__buttons-wrapper {
    display: block;
  }

  .contact__button {

    &--call {
      display: block;
      width: auto;
      margin-right: 0;
      margin-bottom: 20px;
    }

    &--texting {
      display: block;
      width: auto;
    }
  }
}

@media (max-width: $mobile-width) {
  .contact__wrapper {
    padding-left: 20px;
    padding-right: 20px;
  }
}
