.section-header {
  margin-bottom: 30px;

  display: flex;
  justify-content: space-between;
  align-items: center;
}

.section-header__page-title {
  letter-spacing: -2px;
  color: $color-dark-grey;
}

.section-header__button {
  align-self: flex-end;
  min-width: 140px;
  padding: 3px 0;

  font-size: 13px;
  color: $color-light-grey;
  text-align: center;
  vertical-align: middle;

  border: 1px solid $color-light-grey;
  border-radius: 5px;
  cursor: pointer;

  &:hover,
  &:focus {
    opacity: 0.7;
  }
}

@media (max-width: $mobile-width) {
  .section-header {
    flex-direction: column;
  }

  .section-header__page-title {
    margin-bottom: 10px;
  }

  .section-header__button {
    align-self: center;
  }
}
