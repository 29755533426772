.copyright {
  width: 100%;
  display: flex;
  font-size: 13px;
  line-height: 18px;

  color: $color-footer-copyright;

  &__links {
    display: flex;

    a {
      color: inherit;

      &:first-child {
        margin-right: 10px;
      }
    }
  }

  &__developer {
    width: 281px;
    display: flex;
    justify-content: space-between;
    flex-shrink: 0;
    align-items: center;
    color: $color-grey;

    b {
      font-weight: 400;
      color: $color-black;
    }
  }
}

@media (max-width: $tablet-max-width) {
  .copyright {
    font-size: 14px;
    line-height: 19px;

    &__links {
      a:first-child {
        order: 1;
      }
    }

    &__developer {
      width: 200px;
      flex-wrap: wrap;

      span {
        width: 100%;
        margin-bottom: 3px;
      }
    }
  }
}

@media (max-width: $mobile-width) {
  .copyright {
    font-size: 13px;
    line-height: 18px;

    &__links {
      a:first-child {
        order: 0;
      }
    }

    &__developer {
      span {
        // width: 100%;
        margin-bottom: 9px;
      }
    }
  }
}

// @media (min-width: $tablet-width) {
  .copyright__links {
    a[href]:hover,
    a[href]:focus {
      color: $color-blue;
      text-decoration: underline;
    }

    a[href]:active {
      opacity: 0.7;
    }
  }

  .copyright__developer a:hover,
  .copyright__developer a:focus {
    opacity: 0.7;
  }

  .copyright__developer a:active {
    opacity: 1;
  }
// }
